import React, { Component } from 'react';
import { Form } from 'antd';
import { FormContext } from './FormComponent';

const Style = {
    default: {}
};

Style.formItemLayout = {
    wrapperCol: {
        xs: { span: 24 },
    }
};
class FormItem extends Component {
    static contextType = FormContext;

    renderFormItem() {
        const { getFieldDecorator } = this.context;
        const { decoratorKey, decoratorOptions } = this.props;

        if (decoratorKey) {
            return getFieldDecorator(decoratorKey, decoratorOptions)(
                this.props.children
            )
        }
        return this.props.children;
    }

    render() {
        const { label, colon, classNames = '' } = this.props;
        let className = `boro-product-form-item ${classNames}`;
        return (
            <Form.Item
                {...Style.formItemLayout}
                label={label}
                colon={colon || false}
                className={className}
            >
                {this.renderFormItem()}
            </Form.Item>
        );
    }
}

export default FormItem;
