import React, { Component } from 'react';
import { Steps, Breadcrumb } from 'antd';

class LoanSteps extends Component {
    renderBreadcrumb() {
        return (
            <Breadcrumb>
                <Breadcrumb.Item>Loan</Breadcrumb.Item>
                <Breadcrumb.Item>Patient</Breadcrumb.Item>
                <Breadcrumb.Item>Borrower</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    renderSteps() {
        const Step = Steps.Step;
        const { currentStep } = this.props;
        return (
            <Steps progressDot current={currentStep || 1} size="small">
                <Step title="Loan Application" description="New Loan" />
                <Step title="Patient details" description="Add patient details" />
                <Step title="Borrower details" description="Add borrower details" />
                <Step title="Done" description="" />
            </Steps>
        );
    }
    render() {
        return (
            this.renderBreadcrumb()
        );
    }
}

export default LoanSteps;
