import { createReducer } from 'redux-create-reducer'

const initialState = {
    isAuthenticated: false,
    isLoggingIn: false,
    merchant: {},
    product: {},
    borrower: {},
    loan: {
        product: {},
        borrower: {}
    }
};

export default createReducer(initialState, {
    'SET_VALUE'(state, action = {}) {
        return {
            ...state,
            ...action.data
        }
    }
})