import { Form, Icon, Input, Button, message as Message } from 'antd';
import React, { Component } from 'react';
import logo from './boro.png';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Actions from '../../../redux/actions/rootActions';
import history from '../../../utils/history';
import MerchantUtils from '../../../utils/merchant';

const FormItem = Form.Item;

class NormalLoginForm extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.actions = bindActionCreators(Actions, dispatch);
  }

  componentDidMount() {
    if (MerchantUtils.isAuthenticated()) {
      history.push('/home');
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.reducerData.isLoggingIn && this.props.reducerData.isLoggingIn) {
      Message.loading('Logging in, please wait...', 0);
    } else {
      Message.destroy();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.actions.loginMerchant(values)
      }
    });
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const loginButtonStyle = { width: "100%" };
    const isSubmitDisabled = this.props.reducerData.isLoggingIn;
    return (
      <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" disabled={isSubmitDisabled} htmlType="submit" className="login-form-button" style={loginButtonStyle}>
            Log in
        </Button>
        </FormItem>
      </Form>
    )
  }

  renderHeader() {
    const style = {
      logoStyle: {
        height: '60px'
      },
      containerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: "40px"
      }
    }
    return (
      <div style={style.containerStyle}>
        <img src={logo} alt="Logo" style={style.logoStyle} />
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reducerData: state
  }
}

export default connect(
  mapStateToProps
)(NormalLoginForm);