/* eslint-disable */
import React, { Component } from 'react';
import LayoutComponent from '../../common/LayoutComponent';
import { Icon, Input, DatePicker, Radio, AutoComplete, Form, Button, Select, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import logo from '../../../images/boro.png';

class MerchantHome extends Component {
    renderHeader() {
        const style = {
            logoStyle: {
                height: '60px'
            },
            containerStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }
        }
        return (
            <div style={style.containerStyle}>
                <img src={logo} alt="Logo" style={style.logoStyle} />
            </div>
        )
    }

    renderHomeSection() {
        return (
            <div>
                <Link to="/patient">
                    <Button type="primary" size="large">
                        <Icon type="plus" /> New Loan
                    </Button>
                </Link>
            </div>
        );
    }

    render() {
        let containerStyle = {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        };
        let buttonContainerStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
        }
        return (
            <div style={containerStyle}>
                {this.renderHeader()}
                <div style={buttonContainerStyle}>
                    {this.renderHomeSection()}
                </div>
            </div>
        );
    }
}

export default MerchantHome;
