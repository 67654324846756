import { Button, Col, DatePicker, Divider, Icon, Input, message as Message, Modal, Row, Select, Tooltip, Checkbox } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import API from '../../../api';
import MasterData from '../../../data';
import WhatsAppIcon from '../../../images/whatsapp-logo.png';
import * as Actions from '../../../redux/actions/rootActions';
import DummyFixture from '../../../utils/dummy';
import LoanStorage from '../../../utils/loanStorage';
import { FormComponent, FormItem } from '../Forms';
import FormElements from './formElements';

const uuidv4 = require('uuid/v4');
class QuotationFormComponent extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.actions = bindActionCreators(Actions, dispatch);
        this.state = {
            showKnowMorePopup: false,
            showFormSubmitPopup: false,
            disableSubmit: false,
            isFormSubmitted: false
        };
    }

    componentDidMount() {
        const loanGuid = this.getGUIDParam();
        const storedLoanData = this.loadLoanData(loanGuid);
        if (loanGuid && !storedLoanData) {
            this.props.history.replace('/quotation');
        } else {
            const serializedLoanData = this.serializeStoredLoanData(storedLoanData);
            this.props.form.setFieldsValue(serializedLoanData.quotationData);
            this.setState({
                isFormSubmitted: !!serializedLoanData.guid,
                showFormSubmitPopup: !!serializedLoanData.guid
            });
        }
    }

    loadLoanData(guid) {
        let loanData = guid ? LoanStorage.getByGUID(guid) : LoanStorage.getDraftLoan();
        return loanData || null;
    }

    serializeStoredLoanData(loanData = {}) {
        const serializedLoanData = {
            quotation: {},
            ...loanData
        }
        return serializedLoanData;
    }

    getGUIDParam() {
        const { guid } = this.props.match.params;
        return guid;
    }

    fillDummyData() {
        let fieldKeys = Object.keys(DummyFixture.loan);
        let data = {};
        fieldKeys.forEach((key) => {
            data[key] = this.props.form.getFieldValue(key) || DummyFixture.loan[key];
        })
        this.props.form.setFieldsValue({
            ...data
        })
    }

    async createLoan(data) {
        try {
            return await API.createNewLoan(data);
        } catch (err) {
            window.Rollbar.error("Unable to create loan", err);
        }
    }

    createFormSubmissionData(values) {
        // let merchantData = MerchantUtils.getMerchantDataFromUrl();
        let borrowerData = {
            ...values
        };
        let loanData = {
            quotationData: borrowerData,
            guid: uuidv4()
        }
        return loanData;

    }

    sanitizeFormData(loanData) {
        let sanitizedLoanData = {
            ...loanData
        }
        sanitizedLoanData.borrower.dob = sanitizedLoanData.borrower.dob.format('YYYY-MM-DD');
        return sanitizedLoanData;
    }

    async submitLoanToExternal(loanData) {
        try {
            if (!window.rollbar) {
                let slackMssg = {
                    "channel": "boro-test-notifications",
                    "data": {
                        "error": "Did not find Rollbar"
                    },
                    "formatter": "json",
                    "title": "Failed to find rollbar on this page"
                };
                await API.sendSlackMessage(slackMssg);
            }
            window.Rollbar && window.Rollbar.log("New Quotation", { loan: loanData });
        } catch (e) {
            console.log("Something went wrong", e);
        }
    }

    async submitToServer(quotationData) {
        let slackMessage = {
            "channel": "boro-test-notifications",
            "data": quotationData,
            "formatter": "json",
            "title": "New Incoming Quotation"
        };
        try {
            await API.sendSlackMessage(slackMessage);
        } catch (err) {
            window.Rollbar.error("Unable to create loan");
        }
    }

    submitLoanLocally(loanData) {
        LoanStorage.addNew({
            ...loanData,
            isSubmited: true
        });
    }

    async submitLoanData(data) {
        this.submitLoanLocally(data);
        await this.submitLoanToExternal(data);
        await this.submitToServer(data);
    }

    showSuccessPopup(loanData) {
        window.history.pushState(null, null, `/quotation/${loanData.guid}`);
        setTimeout(async () => {
            Message.destroy();
            this.setState({
                showFormSubmitPopup: true
            });
        }, 500);
    }

    async onFormSubmit(e) {
        e.preventDefault();
        this.setState({
            disableSubmit: true
        });
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                let loanData = this.createFormSubmissionData(values);
                Message.loading('Submitting request, please wait...', 0);
                await this.submitLoanData(loanData);
                if (window.gtag_report_conversion) {
                    let guid = loanData.guid || '';
                    window.gtag_report_conversion(`https://dashboard.boroin.com/quotation/${guid}`);
                }
                this.showSuccessPopup(loanData);
            } else {
                this.setState({
                    disableSubmit: false
                });
            }
        });
    }

    getCityStateFromPincode(pincode, pinCodeMaster) {
        let pincodeIndex = pinCodeMaster.pinCode.indexOf(parseInt(pincode));
        if (!pincodeIndex || pincodeIndex < 0) {
            return '';
        }
        let city = pinCodeMaster.city[pincodeIndex];
        let state = pinCodeMaster.state[pincodeIndex];
        return `${city}, ${state}`;
    }

    showModal = (modal) => {
        this.setState({
            [modal]: true,
        });
    };

    handleStateChange(type) {
        let resetField = type === 'company' ? 'companyCity' : 'residenceCity';
        this.props.form.resetFields([resetField]);
    }

    handleOk = (e, popup) => {
        this.setState({
            [popup]: false,
        });
    };

    handleCancel = (e, popup) => {
        this.setState({
            [popup]: false,
        });
    };

    handleTenureChange(val) {
        let loanAmount = this.props.form.getFieldValue('loanRequired');
        this.setTentativeEMIAmount(this.parseToNum(loanAmount), this.parseToNum(val));
    }

    parseToNum(str) {
        return parseInt(str);
    }

    getLocalizedNumber(num) {
        return num.toLocaleString('en-IN');
    }

    getTentativeEMIAmount(loanAmount, prefTenure) {
        if (loanAmount && prefTenure) {
            const tenureCalculationData = MasterData.emiCalculation[`${prefTenure}`];
            const emiAmountRangeStart = tenureCalculationData.interestAmount[0];
            const emiAmountRangeEnd = tenureCalculationData.interestAmount[tenureCalculationData.interestAmount.length - 1];
            const emiRangeStartForLoanAmount = Math.round((loanAmount / 100000) * emiAmountRangeStart);
            const emiRangeEndForLoanAmount = Math.round((loanAmount / 100000) * emiAmountRangeEnd);
            return `${this.getLocalizedNumber(emiRangeEndForLoanAmount)} - ${this.getLocalizedNumber(emiRangeStartForLoanAmount)}`;
        }
        return '';
    }

    setFieldValue(key, value) {
        this.props.form.setFieldsValue({
            [key]: value
        })
    }

    getFieldValue(key) {
        return this.props.form.getFieldValue(key);
    }

    handleFieldChange(val, key) {
        console.log("Event", val, key);
        if (['companyPinCode', 'residencePinCode'].includes(key)) {
            let cityStateValue = this.getCityStateFromPincode(val, MasterData.pinCodeMaster);
            let updateKey = (key === 'companyPinCode') ? 'companyCityState' : 'residenceCityState';
            this.setFieldValue(updateKey, cityStateValue);
        }
        if (['loanRequired', 'emiTenure'].includes(key)) {
            let loanAmount = this.getFieldValue('loanRequired');
            let prefTenure = (key === "emiTenure") ? val : this.getFieldValue('emiTenure');
            let tentativeEMIAmount = this.getTentativeEMIAmount(loanAmount, prefTenure);
            this.setFieldValue('tentativeEmiCalculation', tentativeEMIAmount);
        }
    }

    renderSubmitButton() {
        let isDisabled = this.isFormDisabled();
        return (
            <FormItem>
                <Button type="primary" htmlType="submit" disabled={isDisabled}>
                    Submit<Icon type="cloud-upload" />
                </Button>
            </FormItem>
        );
    }

    renderKnowMoreModal() {
        return (
            <Modal
                title="Boro EMI FAQs"
                visible={this.state.showKnowMorePopup}
                onOk={(e) => this.handleOk(e, 'showKnowMorePopup')}
                onCancel={(e) => this.handleCancel(e, 'showKnowMorePopup')}
                footer={null}
                width="80%"
                style={{
                    top: '2'
                }}
            >
                <iframe className='know-more-modal' title="Know More" src="https://vaibhav698131.typeform.com/to/DaFtze" frameBorder="0" width="100%"></iframe>
            </Modal>
        )
    }

    renderFormSubmitModal() {
        return (
            <Modal
                title="Thank you for choosing Boro!"
                visible={this.state.showFormSubmitPopup}
                onOk={(e) => this.handleOk(e, 'showFormSubmitPopup')}
                onCancel={(e) => this.handleCancel(e, 'showFormSubmitPopup')}
                style={{
                    top: '2'
                }}
                centered={true}
                footer={[
                    <Button style={{marginRight: '4px'}} key="whatsapp" type="link" onClick={this.handleCancel} href="https://wa.me/919019053015" target="_blank">
                        <img src={WhatsAppIcon} alt="WhatsApp" width='24px'/>
                        <span style={{paddingLeft: '3px'}}>WhatsApp </span>
                    </Button>,
                    <Button key="email" type="link" onClick={this.handleOk} href="https://dashboard.boroin.com/loan" target="_blank">
                        {/* <img src={MailIcon} alt="Mail" width='20px'/> */}
                        <span style={{paddingLeft: '3px'}}> Check EMI Eligibility </span>
                    </Button>,
                  ]}
            >
                <div>
                    Hey there, we are working on getting you the best quotations possible! Meanwhile you can check your EMI elligibility <a href="https://dashboard.boroin.com/loan" target="_blank" rel="noopener noreferrer"> here </a>. <br /><br />For any queries, you can always reach us on WhatsApp (+91-9019053015) or Email (emi@boroin.com).
                </div>
            </Modal>
        )
    }

    renderDivider() {
        return (
            <Divider />
        );
    }

    renderFormHeader() {
        return (
            <div style={{ marginBottom: "22px", borderBottom: "1px solid black" }}>
                <span style={{ fontWeight: "bold", fontSize: "24px" }}> Get Quotations </span>
                <Tooltip title="Have a question?">
                    <span onClick={(e) => this.showModal('showKnowMorePopup')}>
                        <Icon
                            style={{ fontSize: '22px', marginLeft: '12px', cursor: 'pointer' }}
                            type="question-circle"
                        />
                    </span>
                </Tooltip>
            </div>
        );
    }

    renderSectionHeaderOld(header) {
        return (
            <div style={{ marginBottom: "24px" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px", borderBottom: "1px solid black" }}> {header} </span>
            </div>
        );
    }

    renderSectionHeader(header) {
        return (
            <div style={{ marginBottom: "24px" }}>
                <span style={{ fontWeight: "500", fontSize: "18px" }}>
                    For any queries, you can always reach out to us on
                    <a  href="https://wa.me/919019053015" target="_blank"><img src={WhatsAppIcon} alt="WhatsApp" width='26px'/>WhatsApp (9019053015)</a>
                </span>
            </div>
        );
    }

    renderSelectOptions(options) {
        let Option = Select.Option;
        return options.map((option) => {
            return (
                <Option key={option.value} value={option.value}>{option.label}</Option>
            );
        })
    }

    isFormDisabled() {
        return !!(this.state.isFormSubmitted || this.state.disableSubmit);
    }

    renderFormInput({ formOptions, elemProps }) {
        let { decoratorKey } = formOptions;
        const disabled = this.isFormDisabled() || !!elemProps.disabled;
        return (
            <Input {...elemProps} disabled={disabled} onBlur={(e) => this.handleFieldChange(e.target.value, decoratorKey)} />
        );
    }

    renderIsAnonymous() {
        return (
            <FormItem
                decoratorKey={'isAnonymous'}
                decoratorOptions={{ valuePropName: 'checked', initialValue: true }}
            >
                <Checkbox> <span style={{fontWeight: 'bold'}}>Share quotations anonymously</span> </Checkbox>
            </FormItem>
        )
    }

    renderFormDatePicker({ formOptions, elemProps }) {
        let { decoratorKey } = formOptions;
        const dateFormat = 'DD/MM/YYYY';
        const disabled = this.isFormDisabled() || !!elemProps.disabled;;
        return (
            <DatePicker
                disabled={disabled}
                format={dateFormat}
                placeholder='Date of Birth'
                style={{ width: '100%' }}
                onChange={(e) => this.handleFieldChange(e, decoratorKey)}
            />
        );
    }

    renderFormSelect({ formOptions, elemProps, selectOptions }) {
        let { decoratorKey } = formOptions;
        const disabled = this.isFormDisabled() || !!elemProps.disabled;
        return (
            <Select {...elemProps} disabled={disabled} onChange={(e) => this.handleFieldChange(e, decoratorKey)}>
                {this.renderSelectOptions(selectOptions)}
            </Select>
        );
    }

    renderElement(element) {
        let formElement = null;
        let { decoratorKey, decoratorOptions, ...extraFormOptions } = element.formOptions;
        switch (element.formType) {
            case 'input':
                formElement = this.renderFormInput(element);
                decoratorOptions['validateTrigger'] = "onBlur";
                break;
            case 'datepicker':
                formElement = this.renderFormDatePicker(element);
                break;
            case 'select':
                formElement = this.renderFormSelect(element);
                break;
            default:
                return null;
        }
        return (
            <FormItem
                decoratorKey={decoratorKey}
                decoratorOptions={decoratorOptions}
                {...extraFormOptions}
                classNames={`form-item-${decoratorKey}`}
            >
                {formElement}
            </FormItem>
        );
    }

    renderPropertyFlat() {
        let projectType = this.props.form.getFieldValue(FormElements.projectType.formOptions.decoratorKey);

        if (projectType !== 'apartment') {
            return null;
        }
        return (
            <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderElement(FormElements.projectName)}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderElement(FormElements.apartmentSize)}
                </Col>
            </Row>
        )
    }

    renderQuotationDetails() {
        return (
            <React.Fragment>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.emailId)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.mobileNo)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.projectType)}
                    </Col>
                </Row>
                {this.renderPropertyFlat()}
            </React.Fragment>
        )
    }

    renderQuotationSections() {
        return (
            <>
                {this.renderQuotationDetails()}
                {this.renderIsAnonymous()}
                {this.renderSubmitButton()}
                {this.renderSectionHeader('Basic details')}
            </>
        )
    }

    renderFormComponent() {
        return (
            <div style={{
                border: '1px solid #dad2d2',
                padding: '28px 48px',
                borderRadius: '8px',
                marginBottom: '24px',
                backgroundColor: 'white'
            }}>
                <FormComponent form={this.props.form} onSubmit={e => this.onFormSubmit(e)}>
                    {this.renderQuotationSections()}
                </FormComponent>
            </div>
        );
    }

    render() {
        return (
            <Row type='flex' justify="center">
                <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={16}
                >
                    {this.renderFormHeader()}
                    {this.renderFormComponent()}
                    {this.renderKnowMoreModal()}
                    {this.renderFormSubmitModal()}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reducerData: state
    }
}

export default connect(
    mapStateToProps
)(QuotationFormComponent);