import React from 'react';
import { Route, Redirect } from "react-router-dom";
import MerchantUtils from '../../../utils/merchant';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = MerchantUtils.isAuthenticated();
    return (
        <Route {...rest} render = {(props) => {
            return (isAuthenticated ? (<Component {...props} />) : (<Redirect to='/login' />))
        }} />
    )
}

export default PrivateRoute;
