import React, { Component } from 'react';
import LayoutComponent from '../../common/LayoutComponent';
import LoginFormComponent from '../../common/LoginComponent';
import { Form } from 'antd';

class Login extends Component {
    render() {
        const LoginForm = Form.create()(LoginFormComponent);
        return (
            <LayoutComponent type="center" style={{flexGrow: 1}}>
                <LoginForm />
            </LayoutComponent>
        );
    }
}

export default Login;
