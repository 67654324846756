import React, { Component } from 'react';
import ProductInformationComponent from '../../common/ProductInformationFormComponent';
import LoanSteps from '../../common/LoanStepsComponent';
import logo from '../../../images/boro.png';
import { Form } from 'antd';

class ProductInformationPage extends Component {
    renderHeader() {
        const style = {
            logoStyle: {
                height: '50px'
            },
            containerStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: '20px'
            }
        }
        return (
            <div style={style.containerStyle}>
                <img src={logo} alt="Logo" style={style.logoStyle} />
            </div>
        )
    }

    renderLoanSteps() {
        return (
            <LoanSteps currentStep={1} />
        );
    }

    renderForm() {
        const ProductInformationForm = Form.create()(ProductInformationComponent);
        return (
            <div>
                <ProductInformationForm />
            </div>
        )
    }

    render() {
        let containerStyle = {
            flexGrow: 1
        }
        return (
            <div style={containerStyle}>
                {this.renderHeader()}
                {this.renderForm()}
            </div>
        );
    }
}

export default ProductInformationPage;
