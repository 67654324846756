import Validator from 'validator';
import MasterData from '../../../data';

function getDecoratorOptions({required = false, message='Field is required', additionalValidationRules=[]}) {
    return {
        rules: [{ required, message }, ...additionalValidationRules]
    };
}

const elements = {
    loanAmount: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'loanRequired',
            decoratorOptions: getDecoratorOptions({
                required: true,
                message: 'Loan amount field is required'
            }),
            label: 'Loan Amount',
        },
        elemProps: {
            type: 'number',
            addonBefore: '₹',
            placeholder: 'Loan Amount (Upto ₹25 Lacs)',
        }
    },
    emiTenure: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'emiTenure',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'EMI Tenure (months)',
        },
        elemProps: {
            placeholder: 'EMI Tenure (months)',
            showSearch: true,
            allowClear: true,
            style: { width: '100%' },
        },
        selectOptions: MasterData.emiTenures
    },
    tentativeEmi: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'tentativeEmiCalculation',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Your tentative EMI',
        },
        elemProps: {
            placeholder: 'Tentative EMI',
            addonBefore: '₹',
            disabled: true,
            required: false
        }
    },
    firstName: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'firstName',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'First Name',
        },
        elemProps: {
            placeholder: 'First Name',
        }
    },
    middleName: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'middleName',
            decoratorOptions: getDecoratorOptions({
                required: false,
            }),
            label: 'Middle Name',
        },
        elemProps: {
            placeholder: 'Middle Name',
        }
    },
    lastName: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'lastName',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Last Name',
        },
        elemProps: {
            placeholder: 'Last Name',
        }
    },
    mobileNo: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'mobileNo',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Mobile no.',
        },
        elemProps: {
            type: 'number',
            placeholder: 'Mobile no.',
            addonBefore: '+91'
        }
    },
    emailId: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'emailId',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && !Validator.isEmail(value)) {
                                callback("Please enter valid email address!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'Email id',
        },
        elemProps: {
            type: 'email',
            placeholder: 'Email id',
        }
    },
    dateOfBirth: {
        formType: 'datepicker',
        formOptions: {
            decoratorKey: 'dob',
            decoratorOptions: getDecoratorOptions({
                required: true
            }),
            label: "Date of Birth"
        },
        elemProps: {
            placeholder: 'Date of Birth',
            style:{ width: '100%' }
        }
    },
    gender: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'gender',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Gender',
        },
        elemProps: {
            placeholder: 'Gender'
        },
        selectOptions: MasterData.gender
    },
    maritalStatus: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'maritalStatus',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Marital Status',
        },
        elemProps: {
            placeholder: 'Marital Status'
        },
        selectOptions: MasterData.maritalStatus
    },
    qualification: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'qualification',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Qualification',
        },
        elemProps: {
            placeholder: 'Qualification'
        },
        selectOptions: MasterData.qualification
    },
    totalWorkExp: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'totalWorkExp',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Total work experience(months)',
        },
        elemProps: {
            type: 'number',
            placeholder: 'Total work experience(months)'
        }
    },
    panCard: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'panCard',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            let panRegex = RegExp("^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$");
                            let testValue = value && value.toUpperCase();
                            if (testValue && !panRegex.test(testValue)) {
                                callback("Please enter valid PAN number!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'Pan Card No.',
        },
        elemProps: {
            placeholder: 'Pan Card No.',
            style: {
                textTransform: 'uppercase'
            }
        }
    },
    residenceType: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'residenceType',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Residence Type (Current)',
        },
        elemProps: {
            placeholder: 'Residence Type (Current)'
        },
        selectOptions: MasterData.residenceType
    },
    residenceDuration: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'monthsCurrentResidence',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Months at current residence',
        },
        elemProps: {
            type: 'number',
            placeholder: 'Months at current residence'
        }
    },
    residencePinCode: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'residencePinCode',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            let intVal = parseInt(value) || '';
                            if (value && MasterData.pinCodeMaster.pinCode.indexOf(intVal) < 0) {
                                callback("Please enter valid pin code!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'PIN Code',
        },
        elemProps: {
            type: 'number',
            placeholder: 'PIN Code'
        }
    },
    residenceCityState: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'residenceCityState',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'City and State',
        },
        elemProps: {
            placeholder: 'Enter PIN Code',
            disabled: true
        }
    },
    residenceAddress: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'residenceAddress',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Residence Address',
        },
        elemProps: {
            placeholder: 'Residence Address',
        }
    },
    typeOfEmployment: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'typeOfEmployment',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Type of Employment',
        },
        elemProps: {
            placeholder: 'Type of Employment'
        },
        selectOptions: MasterData.employmentType
    },
    monthlyIncome: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'monthlyIncome',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Monthly Income / Salary',
        },
        elemProps: {
            type: 'number',
            placeholder: 'Monthly Income / Salary',
        }
    },
    nameOfFirm: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'nameOfFirm',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Name of Firm',
        },
        elemProps: {
            placeholder: 'Name of Firm',
        }
    },
    typeOfCompany: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'companyType',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Type of Company',
        },
        elemProps: {
            placeholder: 'Type of Company'
        },
        selectOptions: MasterData.companyType
    },
    officialEmailId: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'companyEmailId',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && !Validator.isEmail(value)) {
                                callback("Please enter valid email address!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'Official Email id',
        },
        elemProps: {
            type: 'email',
            placeholder: 'Official Email id',
        }
    },
    officePinCode: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'companyPinCode',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            let intVal = parseInt(value) || '';
                            if (value && MasterData.pinCodeMaster.pinCode.indexOf(intVal) < 0) {
                                callback("Please enter valid pin code!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'PIN Code',
        },
        elemProps: {
            type: 'number',
            placeholder: 'PIN Code'
        }
    },
    officeCityState: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'companyCityState',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'City and State',
        },
        elemProps: {
            placeholder: 'Enter PIN Code',
            disabled: true
        }
    },
    officeAddress: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'companyAddress',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Office Address',
        },
        elemProps: {
            placeholder: 'Office Address',
        }
    }
};

export default elements;