import API from '../../api';
import Token from '../../utils/token';
import history from '../../utils/history';

export function loginMerchant(data) {
    return (dispatch) => {
        dispatch(this.setValue({
            isLoggingIn: true
        }));
        API.login(data).then((response) => {
            if (response.data && response.data.token && response.data.user) {
                Token.setMerchant(response.data.token);
                dispatch(this.setValue({
                    merchant: response.data.user,
                    isLoggingIn: false,
                    isAuthenticated: true
                }));
                history.push('/home')
            }
        }).catch((err) => {
            dispatch(this.setValue({
                isLoggingIn: false
            }));
        })
    };
}

export function setValue(data) {
    return {
        type: 'SET_VALUE',
        data: data
    };
}

export function updatePatientData(data) {
    return {
        type: 'UPDATE_PATIENT_DATA',
        data: data
    };
}

export function sendLoanData(data) {
    return (dispatch) => {
        API.login(data).then((response) => {
            console.info('Merchant login response', response);
            if (response.data && response.data.token && response.data.merchant) {
                Token.setMerchant(response.data.token);
                dispatch({
                    type: 'SET_VALUE',
                    data: {
                        merchant: response.data.merchant
                    }
                })
            }
        })
    };
}