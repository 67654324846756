import Validator from 'validator';
import MasterData from '../../../data';

function getDecoratorOptions({required = false, message='Field is required', additionalValidationRules=[]}) {
    return {
        rules: [{ required, message }, ...additionalValidationRules]
    };
}

const elements = {
    projectType: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'projectType',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Type of Property',
        },
        elemProps: {
            placeholder: 'Type of Property',
            showSearch: false,
            allowClear: false,
            style: { width: '100%' },
        },
        selectOptions: MasterData.propertyTypes
    },
    projectName: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'projectName',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Society Name',
        },
        elemProps: {
            placeholder: 'Society Name',
        }
    },
    apartmentSize: {
        formType: 'select',
        formOptions: {
            decoratorKey: 'apartmentSize',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Apartment Size',
        },
        elemProps: {
            placeholder: 'Apartment Size',
            showSearch: false,
            allowClear: true,
            style: { width: '100%' },
        },
        selectOptions: MasterData.apartmentSize
    },
    mobileNo: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'mobileNo',
            decoratorOptions: getDecoratorOptions({
                required: true,
            }),
            label: 'Mobile no.',
        },
        elemProps: {
            type: 'number',
            placeholder: 'Mobile no.',
            addonBefore: '+91'
        }
    },
    emailId: {
        formType: 'input',
        formOptions: {
            decoratorKey: 'emailId',
            decoratorOptions: getDecoratorOptions({
                required: true,
                additionalValidationRules: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && !Validator.isEmail(value)) {
                                callback("Please enter valid email address!");
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }),
            label: 'Email id',
        },
        elemProps: {
            type: 'email',
            placeholder: 'Email id',
        }
    }
};


export default elements;