import axios from "axios";
import * as constants from "../config";
import MerchantUtils from '../utils/merchant';

let customAxios = axios.create({
    baseURL: `${constants.SERVER_ADDR}/${constants.API_PATH}`
});

customAxios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers["Authorization"] = `Bearer ${MerchantUtils.getToken()}`;
        return config;
    },
    function (error) {
        // Do something with request error
        console.error('Request error', error);
        return Promise.reject(error);
    }
);

customAxios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error('Response error', error);
        return Promise.reject(error);
    }
);

const API = {
    login: (data) => {
        let url = `/user/signin`
        return customAxios.post(url, data);
    },

    createNewLoan: (data) => {
        let url = `/loan`;
        return customAxios.post(url, data);
    },

    sendSlackMessage: (data) => {
        let url = `/slack/send`
        return customAxios.post(url, data);
    }
};

export default API;