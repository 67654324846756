import Token from './token';
import {MerchantLogoList} from '../data/merchantlogo.static';
const JWT = require('jsonwebtoken');
const queryString = require('query-string');
const MerchantList = require('../data/merchantlist.static');

const Merchant = {
    isAuthenticated() {
        return (!!this.getAuthData());
    },

    getAuthData() {
        let merchantToken = Merchant.getToken();
        let merchantData = merchantToken && JWT.decode(merchantToken);

        if (merchantToken && merchantData) {
            return {
                token: merchantToken,
                data: merchantData
            }
        }
        return null;
    },

    getToken() {
        let merchantToken = Token.getMerchant() || '';
        return merchantToken;
    },

    isDummyMerchant() {
        let authData = Merchant.getAuthData();
        return (authData && authData.data.username === 'dummy');
    },

    getMerchantIdFromUrl() {
        let {mid} = queryString.parse(window.location.search);
        return parseInt(mid) || null;
    },

    getMerchantDataFromUrl() {
        let mid = this.getMerchantIdFromUrl();
        return this.getMerchantDataFromId(mid);
    },

    getMerchantLogoFromId(id) {
        let logoId = `Mid${id}Logo`;
        return MerchantLogoList[logoId] || null;
    },

    getMerchantDataFromId(mid) {
        if (!mid) {
            return {};
        }
        let defaultMerchant = {
            userid: mid,
            meta: {}
        };
        let merchant = MerchantList.find((_merchant) => {
            return (_merchant.userid === mid);
        });
        return merchant || defaultMerchant;
    }
}

export default Merchant;