import React, { Component } from 'react';

const Style = {
    default: {}
};

Style.root = {
    ...Style.default,
    padding: '20px 30px',
    height: "100%",
    display: 'flex',
    overflowY: 'scroll',
    backgroundColor: '#f2f3f5'
}

Style.center = {
    ...Style.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%"
}

class PageLayout extends Component {
    render() {
        let style = this.props.type ? Style[this.props.type] : Style.default;
        let styleOverrides = this.props.style || {};
        style = { ...style, ...styleOverrides };
        let children = this.props.children || null;
        return (
            <div style={style}>
                {children}
            </div>
        );
    }
}

export default PageLayout;
