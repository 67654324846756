const store = require('store');

const localStorage = {
    get(key) {
        return store.get(key);
    },

    set(key, value) {
        return store.set(key, value);
    },

    loadState() {
        try {
            const serializedState = store.get('state');
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    },

    saveState(state) {
        try {
            const serializedState = JSON.stringify(state);
            store.set('state', serializedState);
        } catch {
            // ignore write errors
        }
    }
}

export default localStorage;
