import React, { Component } from 'react';
import { FormComponent, FormItem } from '../../common/Forms';
import { Icon, Divider, Input, DatePicker, Radio, message as Message, Row, Button, Select, Col } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Actions from '../../../redux/actions/rootActions';
import history from '../../../utils/history';
import API from '../../../api';
import StateCityUtils from '../../../utils/state-city';
import DummyFixture from '../../../utils/dummy';
import MerchantUtils from '../../../utils/merchant';
const uuidv4 = require('uuid/v4');

class BorrowerInformationForm extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.actions = bindActionCreators(Actions, dispatch);
    }

    renderSubmitButton() {
        return (
            <FormItem>
                <Button type="primary" htmlType="submit">
                    Submit Loan<Icon type="cloud-upload" />
                </Button>
            </FormItem>
        );
    }

    fillDummyData() {
        let fieldKeys = Object.keys(DummyFixture.borrower);
        let data = {};
        fieldKeys.forEach((key) => {
            data[key] = this.props.form.getFieldValue(key) || DummyFixture.borrower[key];
        })
        this.props.form.setFieldsValue({
            ...data
        })
    }

    renderDummyFillButton() {
        if (MerchantUtils.isDummyMerchant()) {
            return (
                <FormItem>
                    <Button type="primary" htmlType="button" className="boro-dummy-fill-button" size="small" onClick={(e) => this.fillDummyData()}>
                        Dummy Fill<Icon type="right" />
                    </Button>
                </FormItem>
            );
        }
        return null;
    }


    renderInputButton(key) {
        let placeholder = '';
        let initialValue = '';
        let required = true;
        let requiredMessage = '';
        let type = 'string';
        let addonBefore = null;
        let additionalValidationRules = [];
        let productData = (this.props.reducerData.product && this.props.reducerData.product.isBorrower) ? this.props.reducerData.product : {};
        switch (key) {
            case 'emailId':
                placeholder = "Email id";
                type = "email";
                initialValue = productData.emailId || '';
                break;
            case 'aadharCard':
                placeholder = 'Aadhar Card No.';
                break;
            case 'panCard':
                placeholder = 'Pan Card No.';
                additionalValidationRules = [
                    { validator: (rule, value, callback) => {
                        let mobileRegex = RegExp("^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$");
                        if (value && !mobileRegex.test(value)) {
                            callback("Please enter valid PAN number!");
                        } else {
                            callback();
                        }
                    }}
                ]
                break;
            case 'firstName':
                placeholder = 'First Name';
                initialValue = productData.firstName || '';
                break;
            case 'middleName':
                placeholder = 'Middle Name';
                initialValue = productData.middleName || '';
                required = false;
                break;
            case 'lastName':
                placeholder = 'Last Name';
                initialValue = productData.lastName || '';
                break;
            case 'mobileNo':
                placeholder = "Mobile no.";
                initialValue = productData.mobileNo || '';
                type = "number";
                addonBefore = "+91";
                break;
            case 'residenceAddress':
                placeholder = "Full Address";
                break;
            case 'monthlyIncome':
                placeholder = "Monthly Income";
                type = "number";
                break;
            case 'nameOfFirm':
                placeholder = "Name of Firm";
                break;
            case 'dependants':
                placeholder = "No. of dependents";
                type = "number";
                break;
            default:
                placeholder = ''
        };
        requiredMessage = requiredMessage || `${placeholder} is required`;
        return (
            <FormItem
                decoratorKey={key}
                decoratorOptions={{
                    initialValue: initialValue,
                    rules: [{ required: required, message: requiredMessage }, ...additionalValidationRules],
                    validateTrigger: "onBlur"
                }}
                label={placeholder}
            >
                <Input placeholder={placeholder} addonBefore={addonBefore} type={type} />
            </FormItem>
        );
    }

    renderDOB() {
        const dateFormat = 'DD/MM/YYYY';
        let productData = (this.props.reducerData.product && this.props.reducerData.product.isBorrower) ? this.props.reducerData.product : {};
        return (
            <FormItem
                decoratorKey={'dob'}
                decoratorOptions={{
                    initialValue: productData.dob || '',
                    rules: [{ required: true, message: 'Date of Birth is required' }]
                }}
                label="Date of Birth"
            >
                <DatePicker format={dateFormat} placeholder='Date of Birth' style={{ width: '100%' }} />
            </FormItem>
        );
    }

    renderGender() {
        let RadioGroup = Radio.Group;
        let RadioButton = Radio.Button;
        let productData = (this.props.reducerData.product && this.props.reducerData.product.isBorrower) ? this.props.reducerData.product : {};
        return (
            <FormItem
                decoratorKey={'gender'}
                decoratorOptions={{
                    initialValue: productData.gender || '',
                    rules: [{ required: true, message: 'Gender is required' }]
                }}
                label="Gender"
            >
                <RadioGroup buttonStyle='solid'>
                    <RadioButton value={'male'}>Male</RadioButton>
                    <RadioButton value={'female'}>Female</RadioButton>
                </RadioGroup>
            </FormItem>
        );
    }

    renderTypeOfEmployment() {
        let RadioGroup = Radio.Group;
        let RadioButton = Radio.Button;
        return (
            <FormItem
                decoratorKey={'typeOfEmployment'}
                decoratorOptions={{
                    rules: [{ required: true, message: 'Type of Employment is required' }]
                }}
                label="Type of Employment"
            >
                <RadioGroup buttonStyle='solid'>
                    <RadioButton value={'salaried'}>Salaried</RadioButton>
                    <RadioButton value={'self-employed'}>Self-Employed</RadioButton>
                </RadioGroup>
            </FormItem>
        );
    }

    renderMaritalStatus() {
        let RadioGroup = Radio.Group;
        let RadioButton = Radio.Button;
        return (
            <FormItem
                decoratorKey={'maritalStatus'}
                decoratorOptions={{
                    initialValue: '',
                    rules: [{ required: true, message: 'Marital status is required' }]
                }}
                label="Marital Status"
            >
                <RadioGroup buttonStyle='solid'>
                    <RadioButton value={'single'}>Single</RadioButton>
                    <RadioButton value={'married'}>Married</RadioButton>
                </RadioGroup>
            </FormItem>
        );
    }

    createLoan(data) {
        return API.createNewLoan(data).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Unable to create loan", err);
            throw(err);
        })
    }

    onFormSubmit(e) {
        e.preventDefault();
        let currentBorrowerValue = this.props.reducerData.borrower;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let borrowerData = {
                    ...currentBorrowerValue,
                    ...values
                };
                let loanData = {
                    borrower: borrowerData,
                    merchant: this.props.reducerData.merchant,
                    product: this.props.reducerData.product,
                    guid: uuidv4()
                }
                loanData.borrower.dob = loanData.borrower.dob.toString();
                loanData.product.dob = loanData.product.dob.toString();
                Message.loading('Submitting Loan, please wait...', 0);
                window.Rollbar && window.Rollbar.log( "New Loan created: Browser Direct", { loan: loanData } );
                this.createLoan(loanData).then(() => {
                    Message.destroy();
                    Message.success('Submitted Loan successfully!', 3).then(() => {
                        history.push('/home');
                    });
                }).catch((err) => {
                    Message.destroy();
                    Message.error('Failed to submit loan!', 3);
                    //history.push('/home');
                })
            }
        });
    }

    renderDivider() {
        return (
            <Divider />
        );
    }

    renderResidenceType() {
        let Option = Select.Option;
        return (
            <FormItem
                decoratorKey="residenceType"
                decoratorOptions={{
                    rules: [{ required: true, message: 'Residence Type is required' }]
                }}
                label="Residence Type"
            >
                <Select placeholder="Residence Type">
                    <Option value="own">Own</Option>
                    <Option value="rent">Rent</Option>
                </Select>
            </FormItem>
        )
    }

    handleStateChange() {
        this.props.form.resetFields(["residenceCity"]);
    }

    renderState() {
        let Option = Select.Option;
        let states = StateCityUtils.getAllStates();
        let stateOptions = states.map(state => (<Option value={state}> {state} </Option>));
        return (
            <FormItem
                decoratorKey="residenceState"
                decoratorOptions={{
                    rules: [{ required: true, message: 'State is required' }]
                }}
                label="State"
            >
                <Select placeholder="State" showSearch allowClear onChange={() => this.handleStateChange()}>
                    {stateOptions}
                </Select>
            </FormItem>
        )
    }

    renderCity() {
        let Option = Select.Option;
        let selectedState = this.props.form.getFieldValue('residenceState');
        let cities = StateCityUtils.getAllCitiesForState(selectedState);
        let cityOptions = cities.map((city) => (<Option value={city}> {city} </Option>));
        return (
            <FormItem
                decoratorKey="residenceCity"
                decoratorOptions={{
                    rules: [{ required: true, message: 'City is required' }]
                }}
                label="City"
            >
                <Select placeholder="City" showSearch allowClear>
                    {cityOptions}
                </Select>
            </FormItem>
        )
    }

    renderPersonalDetails() {
        return (
            <React.Fragment>
                {this.renderNameSection()}
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderInputButton('mobileNo')}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderDOB()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderInputButton('emailId')}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderGender()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderMaritalStatus()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderAddressDetails() {
        return (
            <React.Fragment>
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderResidenceType()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 16 }}
                    >
                        {this.renderInputButton('residenceAddress')}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderState()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderCity()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderTypeOfEmployment()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }


    renderDocumentDetails() {
        return (
            <React.Fragment>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 10 }}
                    >
                        {this.renderInputButton('panCard')}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 10 }}
                    >
                        {this.renderInputButton('aadharCard')}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderNameSection() {
        return (
            <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('firstName')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('middleName')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('lastName')}
                </Col>
            </Row>
        )
    }

    renderIncomeInformation() {
        return (
            <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('nameOfFirm')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('monthlyIncome')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('dependants')}
                </Col>
            </Row>
        )
    }

    renderFormComponent() {
        return (
            <div style={{
                border: '1px solid #dad2d2',
                padding: '28px 48px',
                borderRadius: '8px',
            }}>
                <FormComponent form={this.props.form} onSubmit={e => this.onFormSubmit(e)}>
                    {this.renderPersonalDetails()}
                    {this.renderDocumentDetails()}
                    {this.renderDivider()}
                    {this.renderAddressDetails()}
                    {this.renderIncomeInformation()}
                    {this.renderSubmitButton()}
                    {this.renderDummyFillButton()}
                </FormComponent>
            </div>
        );
    }

    renderFormHeader() {
        return (
            <div style={{ marginBottom: "22px", borderBottom: "1px solid black" }}>
                <span style={{ fontWeight: "bold", fontSize: "24px" }}> Borrower Details </span>
            </div>
        );
    }

    render() {
        return (
            <Row type='flex' justify="center">
                <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={16}
                >
                    {this.renderFormHeader()}
                    {this.renderFormComponent()}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reducerData: state
    }
}

export default connect(
    mapStateToProps
)(BorrowerInformationForm);