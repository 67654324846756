import Storage from './localStorage';

const token = {
    getMerchant() {
        return Storage.get('merchent-token');
    },

    setMerchant(value) {
        return Storage.set('merchent-token', value);
    }
}

export default token;
