import Storage from './localStorage';

function getAllLoansStored() {
    try {
        const loansInStorage = Storage.get('loans');
        if (loansInStorage) {
            return JSON.parse(loansInStorage) || [];
        }
        return [];
    } catch(e) {
        return [];
    }
}

const loans = {
    getByGUID(guid) {
        const loanArr = getAllLoansStored();
        const loanForGuid = loanArr.find((l) => {
            return l.guid === guid;
        });
        return loanForGuid || null;
    },

    getDraftLoan() {
        /**Draft loans is loan which has no guid */
        const loanArr = getAllLoansStored();
        return loanArr.find((loan) => {
            return !loan.guid
        });
    },

    addNew(newLoan) {
        let loanArr = getAllLoansStored();
        /**Remove old draft loan if newLoan is a draft loan */
        if (!newLoan.guid) {
            loanArr = loanArr.filter((l) => {
                return !!l.guid;
            });
        }
        loanArr.unshift(newLoan);
        let loanArrStored = loanArr.slice(0, 20);
        return Storage.set('loans', JSON.stringify(loanArrStored));
    }
}

export default loans;