import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import LayoutComponent from './components/common/LayoutComponent';
import LoginPage from './components/pages/LoginPage';
import ProductInformationPage from './components/pages/ProductInformationPage';
import LoanInformationPage from './components/pages/LoanInformationPage';
import BorrowerInformationPage from './components/pages/BorrowerInformationPage';
import MerchantHomePage from './components/pages/MerchantHomePage';
import EmiCalculatorClinic from './components/pages/EmiCalculatorClinic';
import EmiCalculatorPatient from './components/pages/EmiCalculatorPatient';
import ProtectedRoute from './components/common/ProtectedRoute';
import history from './utils/history';
import QuotionFormPage from "./components/pages/QuotationFormPage";

const AppRouter = () => (
  <Router history={history}>
    <LayoutComponent type="root">
      <Switch>
        {/* <ProtectedRoute path="/" exact component={MerchantHomePage} /> */}
        <Redirect from="/" to="/loan" exact />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/loan/:guid?" exact component={LoanInformationPage} />
        <Route path="/quotation/:guid?" exact component={QuotionFormPage} />
        <ProtectedRoute path="/patient" exact component={ProductInformationPage} />
        <ProtectedRoute path="/borrower" exact component={BorrowerInformationPage} />
        <ProtectedRoute path="/home" exact component={MerchantHomePage} />
        <Route path="/emi-calculator-clinic" exact component={EmiCalculatorClinic} />
        <Route path="/emi-calculator-patient" exact component={EmiCalculatorPatient} />
      </Switch>
    </LayoutComponent>
  </Router>
);

export default AppRouter;