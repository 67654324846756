import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import rootReducer from './reducers/rootReducer';
import throttle from 'lodash.throttle';
import storage from '../utils/localStorage';

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === `development`) {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
}

middlewares.push(promise());

const store = createStore(
    rootReducer,
    storage.loadState(),
    applyMiddleware(...middlewares)
);

store.subscribe(throttle(() => {
    storage.saveState({
        merchant: store.getState().merchant
    });
}, 1000));


export default store;