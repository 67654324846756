import pinCodeMasterData from "./pincode";

const MASTER_DATA = {
    qualification: [
        {
            value: 'under-graduate',
            label: 'Under-graduate'
        },
        {
            value: 'graduate',
            label: 'Graduate'
        },
        {
            value: 'post-graduate',
            label: 'Post-graduate'
        },
        {
            value: 'other',
            label: 'Other'
        }
    ],
    maritalStatus: [
        {
            value: 'single',
            label: "Single"
        },
        {
            value: 'married',
            label: "Married"
        },
        {
            value: "other",
            label: "Other"
        }
    ],
    gender: [
        {
            value: 'male',
            label: "Male"
        },
        {
            value: 'female',
            label: "Female"
        }
    ],
    residenceType: [
        {
            value: 'rented',
            label: 'Rented'
        },
        {
            value: 'owned',
            label: 'Owned'
        },
        {
            value: 'parental-owned',
            label: 'Parental Owned'
        },
        {
            value: 'company-alloted',
            label: 'Company Leased or Alloted'
        },
    ],
    companyType: [
        {
            value: "mnc",
            label: "MNC"
        },
        {
            value: "private-limited",
            label: "Private Limited"
        },
        {
            value: "partnership",
            label: "Partnership"
        },
        {
            value: "proprietorship",
            label: "Proprietorship"
        },
        {
            value: "govt",
            label: "Govt"
        },
        {
            value: "other",
            label: "Other"
        }
    ],
    employmentType: [
        {
            value: "salaried",
            label: "Salaried"
        },
        {
            value: 'self-employed',
            label: 'Self-Employed'
        }
    ],
    emiTenures: [
        {
            value: '12',
            label: '12'
        },
        {
            value: "18",
            label: "18"
        },
        {
            value: '24',
            label: '24'
        },
        {
            value: '36',
            label: '36'
        },
        {
            value: '48',
            label: '48'
        },
        {
            value: '60',
            label: '60'
        }
    ],
    pinCodeMaster: pinCodeMasterData,
    emiCalculation: {
        '12': {
            interestAmount: [9083, 8750, 8333],
            interestRate: '0%'
        },
        '18': {
            interestAmount: [6278, 6056, 5778],
            interestRate: '1.5%'
        },
        '24': {
            interestAmount: [4875, 4708, 4500],
            interestRate: '4%'
        },
        '36': {
            interestAmount: [3500, 3333, 3194],
            interestRate: '6.5%'
        },
        '48': {
            interestAmount: [2792, 2687, 2562],
            interestRate: '7.5%'
        },
        '60': {
            interestAmount: [2383, 2283, 2183],
            interestRate: '8.5%'
        }
    },
    propertyTypes: [
        {
            value: 'apartment',
            label: 'Flat / Apartment'
        },
        {
            value: 'villa',
            label: 'Villa'
        },
        {
            value: 'independent-house',
            label: 'Independent House'
        },
        {
            value: 'service-apartment',
            label: 'Service Apartment'
        }
    ],
    apartmentSize: [
        {
            value: '1bhk',
            label: '1 BHK'
        },
        {
            value: '2bhk',
            label: '2 BHK'
        },
        {
            value: '3bhk',
            label: '3 BHK'
        },
        {
            value: '4bhk',
            label: '4 BHK'
        }
    ]
};

export default MASTER_DATA;
