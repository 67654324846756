import React, { Component } from 'react';
import BorrowerInformationFormComponent from '../../common/BorrowerInformationFormComponent';
import logo from '../../../images/boro.png';
import { Form } from 'antd';

class BorrowerInformationPage extends Component {
    renderHeader() {
        const style = {
            logoStyle: {
                height: '60px'
            },
            containerStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: '20px'
            }
        }
        return (
            <div style={style.containerStyle}>
                <img src={logo} alt="Logo" style={style.logoStyle} />
            </div>
        )
    }
    render() {
        let containerStyle = {
            flexGrow: 1
        }
        const BorrowerInformationForm = Form.create()(BorrowerInformationFormComponent);
        return (
            <div style={containerStyle}>
                {this.renderHeader()}
                <BorrowerInformationForm />
            </div>
        );
    }
}

export default BorrowerInformationPage;
