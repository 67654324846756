import React, { Component } from 'react';
import LoanInformationFormComponent from '../../common/LoanInformationFormComponent';
import LoanSteps from '../../common/LoanStepsComponent';
import BoroLogo from '../../../images/boro.png';
import { Form } from 'antd';
import MerchantUtils from '../../../utils/merchant';
import { Row, Col } from 'antd';

class LoanInformationPage extends Component {

    renderHeaderLogo(merchantData) {
        const logoStyle = {
            height: '50px'
        };
        let logo;
        let logoHref = '';
        if (merchantData.name) {
            let merchangLogo = MerchantUtils.getMerchantLogoFromId(merchantData.userid)
            logo = merchangLogo || logo;
        } else {
            logo = BoroLogo;
            logoHref = 'https://www.boroin.com/';
        }
        if (logo) {
            if (logoHref) {
                return (
                    <a href={logoHref} target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="Logo" style={logoStyle} />
                    </a>
                )
            }
            return (
                <img src={logo} alt="Logo" style={logoStyle} />
            );
        }
        return null;
    }

    renderPoweredByBoro() {
        const Styles = {
            poweredByWrapper: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginBottom: '12px'
            },
            poweredByContainer: {
                width: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
            poweredByText: {
                fontSize: '22px',
                fontStyle: 'italic',
                paddingRight: '12px',
                fontWeight: '500',
            },
            poweredByLogoContainer: {
                marginTop: '6px',
                alignSelf: 'flex-end',
            },
            poweredByLogo: {
                height: '40px',
            },
        }
        return (
            <Row type='flex' justify="center">
                <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={16}
                >
                    <div style={Styles.poweredByWrapper}>
                        <div style={Styles.poweredByContainer}>
                            <span style={Styles.poweredByText}> Powered By </span>
                            <a href="https://www.boroin.com" target="_blank" rel="noopener noreferrer" style={Styles.poweredByLogoContainer}>
                                <img src={BoroLogo} alt="Logo" style={Styles.poweredByLogo} />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    renderHeaderName(merchantData) {
        let name = merchantData.name || '';
        const nameStyle = {
            fontSize: '32px',
            fontWeight: 'bold',
            color: '#25799b'
        }
        return (
            <div style={nameStyle}> {name} </div>
        )
    }

    renderHeader() {
        const style = {
            containerStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: '20px'
            }
        }
        let merchantData = MerchantUtils.getMerchantDataFromUrl();

        return (
            <div style={style.containerStyle}>
                {this.renderHeaderLogo(merchantData)}
                {this.renderHeaderName(merchantData)}
            </div>
        )
    }

    renderLoanSteps() {
        return (
            <LoanSteps currentStep={1} />
        );
    }

    renderForm() {
        const LoanInformationForm = Form.create()(LoanInformationFormComponent);
        console.log("Props", this.props);
        return (
            <div>
                <LoanInformationForm {...this.props} />
            </div>
        )
    }

    render() {
        let containerStyle = {
            flexGrow: 1,
            marginBottom: '24px'
        }
        return (
            <div style={containerStyle}>
                {this.renderHeader()}
                {this.renderForm()}
                {this.renderPoweredByBoro()}
            </div>
        );
    }
}

export default LoanInformationPage;
