import moment from 'moment';

const dummyFixture = {
    "product": {
        "firstName": "Test",
        "middleName": "T",
        "lastName": "Patient",
        "mobileNo": "9030727929",
        "isBorrower": true,
        "estimatedCost": "12000",
        "dob": moment("2018-01-01T15:48:40.690Z"),
        "gender": "male",
        "treatmentType": "Dental",
        "loanRequired": "1200",
        "emiTenure": 6
    },
    "borrower": {
        "emailId": "example@example.com",
        "residenceAddress": "G-37, Shastri Nagar, Jaipur",
        "maritalStatus": "single",
        "firstName": "Test",
        "middleName": "T",
        "lastName": "Patient",
        "mobileNo": "9030727929",
        "panCard": "ACAPF7850E",
        "monthlyIncome": "12000",
        "dob": moment("2018-01-01T15:48:40.690Z"),
        "aadharCard": "000011112222",
        "residenceType": "own",
        "dependants": "1200",
        "gender": "male",
        "residenceState": "Rajasthan",
        "nameOfFirm": "Test Firm",
        "residenceCity": "Jaipur",
        "typeOfEmployment": "salaried"
    },
    "loan": {
        "emailId": "example@example.com",
        "residenceAddress": "G-37, Shastri Nagar, Jaipur",
        "maritalStatus": "single",
        "firstName": "Jon",
        "middleName": "",
        "lastName": "Snow",
        "mobileNo": "9030727929",
        "panCard": "ACAPF7850E",
        "monthlyIncome": "12000",
        "dob": moment("2018-01-01T15:48:40.690Z"),
        "aadharCard": "000011112222",
        "residenceType": "own",
        "dependants": "4",
        "qualification": "graduate",
        "gender": "male",
        "residenceState": "Rajasthan",
        "nameOfFirm": "Nightwatch",
        "residenceCityState": "Jaipur, Rajasthan",
        "typeOfEmployment": "salaried",
        "residencePinCode": "302001",
        "monthsCurrentResidence": 12,
        "companyEmailId": "john@example.com",
        "companyType": "private-limited",
        "companyAddress": "G-39, Shastri Nagar, Jaipur",
        "companyState": "Rajasthan",
        "companyCityState": "Jaipur, Rajasthan",
        "companyPinCode": "302001",
        "totalWorkExp": 12,
        "loanRequired": "50000",
        "emiTenure": "12",
        "tentativeEmiCalculation": "4,167 - 4,542"
    }
}

export default dummyFixture;