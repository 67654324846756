/* eslint-disable */
import React, { Component } from 'react';
import { Input, Select, Button, Row, Col } from 'antd';

const Style = {
	default: {}
};

Style.row = {
    marginBottom: '25px',
}


class EmiCalculatorClinic extends Component {

	state = {
		costoftreatment: "",
		tenure: "6",
		balancemonths: "0",
		emi:"0",
		advanceemi: "0",
		processingfee: "0",
		documentationcharge: "0"
	}


	handleCostChange = ( event ) => {

		this.setState({
			costoftreatment: event.target.value,
		})
	}

	handleTenureChange = ( value ) => {

		this.setState({
			tenure: value,
		})
	}

	calculate = () => {
		let costoftreatment = parseInt(this.state.costoftreatment)
		let tenure = parseInt(this.state.tenure)

		if(tenure === 6){
			var balancemonths = 5;
			var interest = 0;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 12){
			var balancemonths = 8;
			var interest = 0;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 18){
			var balancemonths = 12;
			var interest = 0.05;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 24){
			var balancemonths = 17;
			var interest = 0.06;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 30){
			var balancemonths = 21;
			var interest = 0.07;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 36){
			var balancemonths = 26;
			var interest = 0.08;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}
		else if(tenure === 48){
			var balancemonths = 36;
			var interest = 0.09;
			this.setState({
				balancemonths: balancemonths.toString()
			})
		}

		let processingfee = (0.0236 * costoftreatment).toString()
		let emi = ((costoftreatment+(costoftreatment * interest * (tenure/12)))/tenure)
		let advanceemi = emi * (tenure - balancemonths)
		console.log(interest)
		console.log(advanceemi)
		console.log(balancemonths)
		this.setState({
			processingfee: Math.round(processingfee).toString(),
			emi: Math.round(emi).toString(),
			advanceemi: Math.round(advanceemi).toString(),
			documentationcharge: "590"
		})
	}

    render() {
    	const Option = Select.Option;
    	let rowstyle = Style.row;
        return (
        	<div>
	        	<Row>
	        		<span>Enter cost of treatment:</span>
	        	</Row>
        		<Row style={rowstyle}>
	        		<Input placeholder="Enter Cost of Treatment" onChange={ this.handleCostChange }/>
	        	</Row>
	        	<Row>
	        		<span>Select Tenure(Number of Months):</span>
	        	</Row>
	        	<Row style={rowstyle}>
	        		<Select defaultValue="6" style={{ width: 120 }} onChange={ this.handleTenureChange }>
						<Option value="6">6</Option>
						<Option value="12">12</Option>
						<Option value="18">18</Option>
						<Option value="24">24</Option>
						<Option value="36">36</Option>
						<Option value="48">48</Option>
					</Select>
				</Row>
				<Row style={rowstyle}>
					<Button type="primary" onClick={ this.calculate }>Calculate</Button>
				</Row>
				<Row style={rowstyle}>
					<span>The calculated EMI is Rs. </span>
					<span>	{this.state.emi}  </span>
					<span>to be paid over a period of  </span>
					<span>	{this.state.balancemonths}  </span>
					<span>months</span><br />
					<span>The calculated Advance EMI is Rs.  </span>
					<span>	{this.state.advanceemi}  </span><br />
					<span>The processing fee is Rs.</span>
					<span>	{this.state.processingfee} </span><br />
					<span>The documentation charge is Rs. </span>
					<span>	{this.state.documentationcharge}  </span>
				</Row>
        	</div>
        );
    }
}

export default EmiCalculatorClinic;
