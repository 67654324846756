const formattedMerchantList = [
    {
        "userid": 33000011,
        "name": "JS Modular Interior Decorators",
        "role": "merchant",
        "meta": {
            "email_id": "jsinteriorsblr@gmail.com",
            "contact_no": "9880096008 (Sanjay Sharma), 8310188345",
            "locality": "",
            "address": "No. 4, Sy No. 112/2, Rampura, Avalahalli Main Road, Virgo Nagar Post, Bengaluru - 560049",
            "website_url": "https://jsinteriorsblr.webs.com/",
            "facebook_url": "https://www.facebook.com/pages/category/Interior-Design-Studio/Js-modular-interiors-decorators-1659583750988577/",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000012,
        "name": "Attitude Interior Designs",
        "role": "merchant",
        "meta": {
            "email_id": "sreekumarnair6@gmail.com, attitudeinteriordesigns@gmail.com",
            "contact_no": "9164662200 (Sreekumar Nair), 080-42092972",
            "locality": "Sarjapur",
            "address": "25/2, Halnayakhana Hall, Near Wipro Corporate Office, Off Sarjapur Rd, Bengaluru, Karnataka 560035",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000013,
        "name": "DSK Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9632065764 (Durgesh)",
            "locality": "",
            "address": "",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000014,
        "name": "KLP Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "klpinterior@gmail.com",
            "contact_no": "9845032619 (Kanhiyalol)",
            "locality": "K.R Puram",
            "address": "#109/1,K. CHansandra, Rampura Main Road, K.R Puram Taluk, Bangalore",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000015,
        "name": "Zenia Kitchens",
        "role": "merchant",
        "meta": {
            "email_id": "zenia kitchens <zeniaindia@gmail.com>,\ngauravbhandari33@gmail.com,",
            "contact_no": "8023311801, 9880077888 ( Gaurav Bhandari)",
            "locality": "Kumarapark West",
            "address": "25/3, Serpentine Street, Kumarapark West,, Bengaluru, Karnataka 560020",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000016,
        "name": "Alona Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "sales@alonainteriors.com",
            "contact_no": "9886788378 (Saji Joseph)z",
            "locality": "Koramangala",
            "address": "Magnus Hospital, #80, 1st Block, 1st Main, ST Bed Layout Near, Koramangala, Bengaluru, Karnataka 560034",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000017,
        "name": "Puran Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "hello@puraninteriors.com",
            "contact_no": "95914 81111",
            "locality": "Bommanahalli",
            "address": "#5 & 6, Sy no 49/8 & 50,\nNext to Anand Honda Service Station,\nHosur Main Road, Begur Hobli, Bommanahalli,\nBangalore - 560068",
            "website_url": "http://www.puraninteriors.com/",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000018,
        "name": "Manning",
        "role": "merchant",
        "meta": {
            "email_id": "sales@manning.in",
            "contact_no": "9958018731 , 9606179133 (Prashant), 8095922888",
            "locality": "",
            "address": "",
            "website_url": "http://www.manning.in/",
            "facebook_url": "https://www.facebook.com/manninghome/",
            "customer_enquiries": 1
        }
    },
    {
        "userid": 33000019,
        "name": "Yellow Tree Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "modular.interiors@outlook.com",
            "contact_no": "9035049343 (Abhishek)",
            "locality": "Mahadevpura",
            "address": "#91/1, Sri Krishna Temple Road Garudacharpalya, Landmark:, Brigade Metropolis, back gate, Mahadevapura, Bengaluru, Karnataka 560048",
            "website_url": "https://www.yellowtreeinteriors.com/",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000020,
        "name": "Arsh Interior Designers",
        "role": "merchant",
        "meta": {
            "email_id": "arshinteriors37@gmail.com, info@arshinteriordesigners.com",
            "contact_no": "88926-77595, 81230-44771 (Hussain)",
            "locality": "BTM Layout",
            "address": "#24, Shop #4, 8th Cross, 60 Feet Road, 20th Main Road, Near BTM Layout, 1st Stage Gangotri Circle, Bangalore - 560068",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000021,
        "name": "8Square",
        "role": "merchant",
        "meta": {
            "email_id": "george.g@8square.in - george",
            "contact_no": "+91 7406550000",
            "locality": "Indiranagar",
            "address": "1st floor, #729, Chinmaya Mission Hospital Rd, First Stage, Stage 1, Indiranagar, Bengaluru, Karnataka 560038",
            "website_url": "http://www.8square.in/",
            "facebook_url": "",
            "customer_enquiries": 1
        }
    },
    {
        "userid": 33000022,
        "name": "Solis Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "solis.interiors@gmail.com",
            "contact_no": "9409254056 (Amarjeet Singh), 7665588577 (Jayantilal Solanki)",
            "locality": "Whitefield",
            "address": "VBHC serene town, Bidrahalli, Kannamangala",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/pages/category/Interior-Design-Studio/Solis-Interiors-495425447595113/",
            "customer_enquiries": 1
        }
    },
    {
        "userid": 33000023,
        "name": "Addon Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "mdsr@addoninteriors.com",
            "contact_no": "973168833(Mohammed Mudassir), 098867 89666",
            "locality": "BTM Layout",
            "address": "No. 12, 3rd floor, Shravanthi Blue Chip, 100ft, Outer Ring Rd, BTM Layout 1, Bengaluru, Karnataka 560068",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000024,
        "name": "The Designscape Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "pangeainteriors7@gmail.com",
            "contact_no": "9620779348 - Pavitra",
            "locality": "Marathahalli",
            "address": "#1096, Opposite to Tulsi Theatre, Tulasi Theatre Road, Marathahalli Village, Marathahalli, Bengaluru, Karnataka 560037",
            "website_url": "https://pangeainteriors.com/",
            "facebook_url": "https://www.facebook.com/Pangeainteriors",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000025,
        "name": "Cosimo Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "cosimointeriors14@gmail.com, veneezakitchens2000@gmail.com",
            "contact_no": "9900564950 (Rose James), 9972372575, 9035004443, 7411290471, 080 28439133",
            "locality": "Sarjapura Main Road",
            "address": "Cosimo Interiors (Veneeza Cucine unit), No-117/1,Doddakannali, Carmelram P. O., Sarjapura Main Road",
            "website_url": "cosimointeriors.com",
            "facebook_url": "https://www.facebook.com/CosimoInteriors",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000026,
        "name": "Blue Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "interiorsblue@gmail.com, vendors.blueinteriors@gmail.com, career.blueinteriors@gmail.com",
            "contact_no": "812 338 7210 (Pooja), 9739442990 (Meet Goyal)zz",
            "locality": "Sarjapur",
            "address": "#155, Ambalipura Sarjapura Road, Kodathi Gate, Bengaluru",
            "website_url": "https://blueinteriors.in/",
            "facebook_url": "https://www.facebook.com/Interiorsblue",
            "customer_enquiries": 1
        }
    },
    {
        "userid": 33000027,
        "name": "Urban Living Designs",
        "role": "merchant",
        "meta": {
            "email_id": "Mr. Utpal - sales@urbanlivingdesigns.in",
            "contact_no": 8073098119,
            "locality": "JP Nagar",
            "address": "45, Byrappa Circle, Gottigere Road Near Jumbo Savari Bus Stand, Jumbo Sawari, Royal County, 8th Phase, J. P. Nagar, Bengaluru, Karnataka 560076",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000028,
        "name": "Veetee Cucina",
        "role": "merchant",
        "meta": {
            "email_id": "admin@veeteecucina.com  veeteecucina@gmail.com",
            "contact_no": "9972372683- Taiju Josph, 9901714912, 9108086444",
            "locality": "Sarjapur",
            "address": "BKS Complex , TC HallI Dommasandra Circle, Sarjapur Main Rd, Choudadenahalli, Bengaluru, Karnataka 562125",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000029,
        "name": "Veterans Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@veteransinteriors.com , veneezawhitefield@gmail.com",
            "contact_no": "99723 72573, 9042850977 (Toji), 7338630606, 8971691512 (Jithin Jyothibas, Manager)",
            "locality": "HSR Layout",
            "address": "Sarjapur Main Rd, Agara Village, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000030,
        "name": "FDC Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "shamanth.nk@gmail.com, vilasev@gmail.com",
            "contact_no": "9620200324 (Shamanth NK)",
            "locality": "Banashankari",
            "address": "100 feet ring road, banashankari 3rd stage, kathiguppe iv phase, gururaja layout, banashankari, bengalore, karnataka- 560085",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000031,
        "name": "IIWA Properties & Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "+919008590066",
            "locality": "HSR Layout",
            "address": "1722, 27th Cross Rd, Garden Layout, Sector 3, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000032,
        "name": "WS Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@wsinteriors.in",
            "contact_no": "9545551239(Mahipal), 7353034567 (Prabodh)",
            "locality": "HSR Layout",
            "address": "15, 27th Main Rd, Phase 3, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000033,
        "name": "Service Bell Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "support@servicebell.com",
            "contact_no": "9886750105(Aman Patro)",
            "locality": "Brookefield",
            "address": "5, Behind Apollo Medical Centre, 2, Varthur Rd, BEML Layout, Brookefield, Bengaluru, Karnataka 560066",
            "website_url": "https://www.servicebell.com/",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000034,
        "name": "Differenze Dimensions",
        "role": "merchant",
        "meta": {
            "email_id": "dedimensions@gmail.com",
            "contact_no": "9845603441 (Sanjeev)",
            "locality": "Ramamurthy Nagar",
            "address": "#20, 2nd Floor, 3rd Cross, 2nd Block, Sir MV Nagar, Ramamurthy Nagar, Bangalore - 560016",
            "website_url": "",
            "facebook_url": "",
            "customer_enquiries": ""
        }
    },
    {
        "userid": 33000035,
        "name": "Bonito Designs",
        "role": "merchant",
        "meta": {
            "email_id": "hello@bonito.in",
            "contact_no": "08066085646\n, 9535388266 (Pallab)",
            "locality": "HSR Layout",
            "address": "Building No. 1182, 5th Main Road, 7th Sector, HSR Layout",
            "website_url": "https://bonito.in/",
            "facebook_url": "https://www.facebook.com/bonitodesigns/"
        }
    },
    {
        "userid": 33000036,
        "name": "Solortus Designz",
        "role": "merchant",
        "meta": {
            "email_id": "info@solortusdesignz.com",
            "contact_no": "9632374618, 9739388821",
            "locality": "Sarjapura Road Off, Doddakanneli",
            "address": "#330/1, Off Sarjapur Rd, Doddakannelli, Bengaluru, Karnataka 560035",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000037,
        "name": "Disegno",
        "role": "merchant",
        "meta": {
            "email_id": "disegno34@gmail.com",
            "contact_no": "‭096637 77899‬ (Chetan)",
            "locality": "Koramangala",
            "address": "#219, 5th cross, 15th main, 4th block, Kormangala, Bangalore",
            "website_url": "https://disegno-interiordesigner.business.site/?utm_source=gmb&utm_medium=referral",
            "facebook_url": "www.fb.com/DesiningLifestyles"
        }
    },
    {
        "userid": 33000038,
        "name": "WelFurn",
        "role": "merchant",
        "meta": {
            "email_id": "pratik@welfurn.com, mahesh@welfurn.com, rahul@welfurn.com",
            "contact_no": "9113218706 (Pratik), 9742455933",
            "locality": "Rajajinagar",
            "address": "Bearing No 26-1A, First Floor (Rear Part) 1st Main Road, Industrial Town Rajajinagar",
            "website_url": "https://www.welfurn.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000039,
        "name": "Concepto Studio",
        "role": "merchant",
        "meta": {
            "email_id": "contact@conceptostudio.in",
            "contact_no": "8861636827, 8861636828",
            "locality": "Banaswadi",
            "address": "T3, RS Apartment, #9, 6th Cross Road, Horamavu Main Road, 3rd Main Rd, Manjunath Nagar, Hoysala Nagar, Banasawadi, Bengaluru, Karnataka 560043",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000040,
        "name": "Yash Design",
        "role": "merchant",
        "meta": {
            "email_id": "mail@yashdesignindia.com",
            "contact_no": "934 309 0666 ( Nikhilesh), 802 258 7548",
            "locality": "HSR Layout",
            "address": "640, 2nd Floor, 12th Cross Rd, Sector 2, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000041,
        "name": "De Qualita",
        "role": "merchant",
        "meta": {
            "email_id": "punit@dequalita.com",
            "contact_no": "099025 19999, 8555925229 (Punit)",
            "locality": "HSR Layout",
            "address": "1st floor, above airtel store, 27th main, HSR Layout 1st Sector 2797, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000042,
        "name": "Space and Forms",
        "role": "merchant",
        "meta": {
            "email_id": "spaceandforms@gmail.com",
            "contact_no": "9880712311 (Balamurali - owner)",
            "locality": "Koramangala",
            "address": "56, 7th B Cross Rd, KHB Block Koramangala, Koramangala 4-B Block, Koramangala 4th Block, Koramangala, Bengaluru, Karnataka 560034",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000043,
        "name": "VDN Home Decorz",
        "role": "merchant",
        "meta": {
            "email_id": "vdnhomedecorz@gmail.com",
            "contact_no": "7676744755 (Chaitu Chakri), 9666700076",
            "locality": "Sarjapur",
            "address": "NEAR, #64/2 CHOODASANDRA MAIN ROAD , KASAVANAHALLI, Hosa Rd, Sarjapur, Bengaluru, Karnataka 560035",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000044,
        "name": "PS Designs",
        "role": "merchant",
        "meta": {
            "email_id": "padma@psdesigns.in, prabhakar@psdesigns.in",
            "contact_no": "9611 119 120, 9902 657 733",
            "locality": "HSR Layout",
            "address": "1st floor SNR Plaza,13th Cross, 27th Main, Hsr Layout, 1st Sector, HSR Layout, Bengaluru",
            "website_url": "http://psdesigns.in/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000045,
        "name": "Studio Gritt",
        "role": "merchant",
        "meta": {
            "email_id": "hello@studiogritt.com",
            "contact_no": "78297 60981",
            "locality": "Koramangala",
            "address": "5th Floor, 439, 18th Main, Koramangala 6th Block",
            "website_url": "https://studiogritt.com/",
            "facebook_url": "https://www.facebook.com/studiogrittindia/"
        }
    },
    {
        "userid": 33000046,
        "name": "Sandarbh Design Studio",
        "role": "merchant",
        "meta": {
            "email_id": "sai@sandarbharchitects.com, design@sandarbharchitects.com",
            "contact_no": "98452 01336, 86068 43112",
            "locality": "Horamavu-Kalkere Main Road",
            "address": "Horamavu main road, Esther Enclave Layout, 4th Main Rd, Rajkumar Layout, Kalkere, Bengaluru, Karnataka 560043",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000047,
        "name": "Truww",
        "role": "merchant",
        "meta": {
            "email_id": "founders@honestcollars.com , contact@truww.com",
            "contact_no": "080-30752978",
            "locality": "Chikkakannalli",
            "address": "139, 5th Cross Rd, HAL 3rd Stage, Hadosiddapura, Chikkakannalli, Bengaluru, Karnataka 560099",
            "website_url": "https://truww.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000048,
        "name": "Dwello",
        "role": "merchant",
        "meta": {
            "email_id": "info@dwello.biz",
            "contact_no": "89714 23727, 9845377465- mayur",
            "locality": "Dommasandra",
            "address": "Dommasandra, Bengaluru, Karnataka 562125",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000049,
        "name": "FYI Design Studios",
        "role": "merchant",
        "meta": {
            "email_id": "karthik@fyidesignstudios.com, info@fyidesignstudios.com",
            "contact_no": "9844962713 (Vijay Karthik Menon)",
            "locality": "Marathahalli",
            "address": "iKeve, Level 8, Tower 1, Umiya Business Bay, Outer Ring Rd, Marathahalli, Bengaluru, Karnataka 560203",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000050,
        "name": "Indigo Child",
        "role": "merchant",
        "meta": {
            "email_id": "sidharth@indigochild.in",
            "contact_no": "80959 99989 (Sidharth)",
            "locality": "HBR Layout",
            "address": "Indigo Child Projects & Implementation, 5th Block, HBR Layout",
            "website_url": "http://indigochild.in/",
            "facebook_url": "https://www.facebook.com/indigochildDCPI/"
        }
    },
    {
        "userid": 33000051,
        "name": "Global Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "global.intcons@gmail.com",
            "contact_no": 9845148927,
            "locality": "BTM Layout",
            "address": "",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000052,
        "name": "Elements Kitchens",
        "role": "merchant",
        "meta": {
            "email_id": "manoj@elements4kitchens.com, elementsbangalore@gmail.com",
            "contact_no": "99002 69592 (manoj), 9972393397",
            "locality": "Jayanagar",
            "address": "1st Floor, #539, 10th Main Rd, 38th Cross, 4th Block, 5th Block, Jayanagar",
            "website_url": "https://www.elements4kitchens.com/",
            "facebook_url": "https://www.facebook.com/ElementsKitchensSolutions/"
        }
    },
    {
        "userid": 33000053,
        "name": "Exodus Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "exodusinteriorsdesign@gmail.com",
            "contact_no": "9886838884 (David Rajesh)",
            "locality": "",
            "address": "No. 2, Varanasi Main Road, Putthuswamy Layout, Near Lotus Apartment, Bangalore - 560036",
            "website_url": "http://exodusinteriors.com/",
            "facebook_url": "https://www.facebook.com/exodusinteriors.d.9"
        }
    },
    {
        "userid": 33000054,
        "name": "Armino Designs",
        "role": "merchant",
        "meta": {
            "email_id": "info@arminodesigns.com",
            "contact_no": "8861049011 (Deep)",
            "locality": "HSR Layout",
            "address": "#2608 , HSR Layout , Provident Fund Office",
            "website_url": "https://www.arminodesigns.com/",
            "facebook_url": "https://www.facebook.com/Arminodesigns/"
        }
    },
    {
        "userid": 33000055,
        "name": "Seventh Sky Architects",
        "role": "merchant",
        "meta": {
            "email_id": "seventhskyfirm@gmail.com",
            "contact_no": "99169 61182",
            "locality": "VV Puram",
            "address": "#9, Back side of Sri Raghavendra Nilaya, Shraddhananda Bhavan cross road, VVPuram",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/linkseventhsky/"
        }
    },
    {
        "userid": 33000056,
        "name": "Saventure",
        "role": "merchant",
        "meta": {
            "email_id": "saventureinfratech@gmail.com",
            "contact_no": "9148080666, 9972678764, 7619462594",
            "locality": "Rajajinagar",
            "address": "16/19, 1st floor, 1st Main, Rajajinagar industrial Town, Chord Rd, Rajajinagar",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000057,
        "name": "Wood Décor",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9242058470 (Bansi Lal), 8095459498 (Deepesh)",
            "locality": "Banaswadi",
            "address": "No.4/2, Samra Plaza, St Marks Road, Bangalore - 560001, Opposite to Nandini Hotel (Map)",
            "website_url": "http://wooddecor.in",
            "facebook_url": "https://www.facebook.com/wooddecor/"
        }
    },
    {
        "userid": 33000058,
        "name": "Lifestyle Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "089265 55522",
            "locality": "Hormavu",
            "address": "210, 2, 1st Cross St, Narayanappa Layout, Horamavu Agara, Horamavu, Bengaluru, Karnataka 560043",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000059,
        "name": "Grafity Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@grafityinteriors.com",
            "contact_no": "7022 480 246",
            "locality": "Kattigenahalli",
            "address": "#14, Sri Krishna Garden, Kattigenahalli",
            "website_url": "http://grafityinteriors.com/",
            "facebook_url": "https://www.facebook.com/GrafityInteriors/"
        }
    },
    {
        "userid": 33000060,
        "name": "Asense Interior",
        "role": "merchant",
        "meta": {
            "email_id": "asenseinterior@gmail.com",
            "contact_no": "7676375901 (Mrinmoy Mukherjee - owner), 7676297854",
            "locality": "Whitefield",
            "address": "129, Siddapura, Whitefield, Bengaluru, Karnataka, India, Varthur Main Road, Bengaluru, Karnataka 560066",
            "website_url": "https://www.asenseinterior.com/",
            "facebook_url": "https://www.facebook.com/AsenseInterior"
        }
    },
    {
        "userid": 33000061,
        "name": "Wudbell",
        "role": "merchant",
        "meta": {
            "email_id": "manoj9009@yahoo.com, support@wudbell.com, design@wudbell.com, careers@wudbell.com",
            "contact_no": "9975555226 (Manoj), 9845212833 (Yogesh), 8147274118 (Sajith)",
            "locality": "Whitefield",
            "address": "#113/1 Nagondanahalli, Vijay Nagar Main Rd, Near NVT Life Square, Whitefield",
            "website_url": "https://wudbell.com/",
            "facebook_url": "https://www.facebook.com/wudbell/"
        }
    },
    {
        "userid": 33000062,
        "name": "Magnon",
        "role": "merchant",
        "meta": {
            "email_id": "rk@magnonindia.com, deepak@magnonindia.com, reachus@magnonindia.com",
            "contact_no": "7406710336 (Deepak Parija - Sales), 7022593001 (Radhakrishnan RK)",
            "locality": "Jayachamarendra Industrial Layout",
            "address": "No 4, 1st Floor, Kanakapura Main Road, Jayachamarendra Industrial Layout, Yelchenahalli, 1st Stage, Bengaluru - 560062",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000063,
        "name": "The Studio Bangalore",
        "role": "merchant",
        "meta": {
            "email_id": "sales@thestudiobangalore.com",
            "contact_no": "9900 511 699 (Nandita Manwani), +91 9482 594 088 (Yogi)",
            "locality": "JP Nagar",
            "address": "D-1303 Mantri Serenity, Kanakapura Road, Bengaluru, Karnataka 560062",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000064,
        "name": "Homzinterio",
        "role": "merchant",
        "meta": {
            "email_id": "support@homzinterio.in",
            "contact_no": "99 0188 7777 (Vijay- owner), 080 4890 8718",
            "locality": "Whitefield",
            "address": "Orchid Woods, No. 13, Kundalahalli, Brookefield, Bengaluru, Karnataka 560037",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000065,
        "name": "De Square",
        "role": "merchant",
        "meta": {
            "email_id": "naveen@desquare.net",
            "contact_no": "9741961016 (Naveen), 080-43726413",
            "locality": "Indiranagar",
            "address": "F, 17, 17th Cross Rd, Stage 2, Hoysala Nagar, Indiranagar, Bengaluru, Karnataka 560038",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000066,
        "name": "Creative Axis",
        "role": "merchant",
        "meta": {
            "email_id": "marketing@thecreativeaxis.com",
            "contact_no": "9243 438325 (Amrath) / 9900 002635 (Anupama)",
            "locality": "Basavanagara",
            "address": "1st Floor, Kaveri Street, Opp Shell Petrol Bunk, Basavanagara, Bengaluru, Karnataka 560037",
            "website_url": "https://www.thecreativeaxis.com/",
            "facebook_url": "https://www.facebook.com/TheCreativeAxis/"
        }
    },
    {
        "userid": 33000067,
        "name": "Monnaie",
        "role": "merchant",
        "meta": {
            "email_id": "info@monai.in, enquiry@monnaie.in",
            "contact_no": 9625080808,
            "locality": "Koramangala",
            "address": "1st Floor, Door No., 7, 80 Feet Main Road, Koramangala 1A Block, 1st Block Koramangala, Koramangala, Bangalore, 1st Block Koramangala",
            "website_url": "https://www.monnaie.in",
            "facebook_url": "https://www.facebook.com/monnaie.in"
        }
    },
    {
        "userid": 33000068,
        "name": "Skyrah Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "skyrahinteriors@gmail.com",
            "contact_no": "kumara (8431731572) , amar",
            "locality": "K.R Puram",
            "address": "Basavanapura Main Rd, Vijaya Bank Colony, Gokula Layout, Krishnarajapura, Bengaluru, Karnataka 560036",
            "website_url": "www.skyrahinteriors.com",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000069,
        "name": "Arjun View Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "interiorarjunview@gmail.com",
            "contact_no": "9163363930, 9433845119",
            "locality": "",
            "address": "Near central metro station",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000070,
        "name": "Pencil Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@pencilinteriors.in, info@pencilinteriors.co.in",
            "contact_no": "7090346555 (Sriju Krishnan), 080-4125 8232",
            "locality": "Bellandur",
            "address": "Syno-482/57, First Floor, Hemareddy building Sarjapura Bellandur Conct Road, Near to Nitesh Estate - Cape Cod, Bellandur, Bengaluru, Karnataka 560103",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000071,
        "name": "Aishwarya Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@aishwaryainteriors.in",
            "contact_no": "7829767575 (Yogesh - owner)",
            "locality": "Bellandur",
            "address": "# 31,1st Floor, Above Cafe Coffee Day, Opp. Bangalore Central Mall, Bellandur Gate, Marathalli, Outer Ring Road, Bellandur,",
            "website_url": "https://www.aishwaryainteriors.in/",
            "facebook_url": "https://www.facebook.com/AishwaryaInteriorsPvtLtd/"
        }
    },
    {
        "userid": 33000072,
        "name": "Dream Zone",
        "role": "merchant",
        "meta": {
            "email_id": "chandrashekar@dreamzoneinteriors.com, shankar@dreamzoneinteriors.com, 6shekar@gmail.com",
            "contact_no": "9632832123 (Shankar Randeer), 9741451133, 080-68888931",
            "locality": "Indiranagar",
            "address": "No. 198, 2nd Floor, Manjunatha Complex, CMH Road, Above Shanthi Sagar Hotel, Indiranagar 2nd Stage, Bengaluru, Karnataka 560038",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000073,
        "name": "Decorpot",
        "role": "merchant",
        "meta": {
            "email_id": "hello@decorpot.com, Ishank@decorpot.com",
            "contact_no": "9108601000 (Ishank), 9108606000 (Rekha), 9108602000",
            "locality": "HSR Layout",
            "address": "No 17, 2nd floor, 18th Cross Rd, Sector 4, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000074,
        "name": "Modulart",
        "role": "merchant",
        "meta": {
            "email_id": "contact@modulart.in",
            "contact_no": "77958 35554 (Subhodh Balur)",
            "locality": "HSR Layout",
            "address": "L75, 1st Floor, 15th Cross Road, 5th Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000075,
        "name": "Newtros Services",
        "role": "merchant",
        "meta": {
            "email_id": "arvind.kulkarni@newtros.com",
            "contact_no": 9972023932,
            "locality": "BTM Layout",
            "address": "#18/9,3rd&4th floor, A R Plaza, 100 feet Ring Road, BTM Layout 1st stage, new guruppanapalya, bangalore- 560029",
            "website_url": "http://www.newtros.com",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000076,
        "name": "Horizon Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@horizoninteriors.in",
            "contact_no": "9011007341, 9881040820, 020 2691 2942",
            "locality": "Anekal",
            "address": "44,Kada Agrahara,Sarjapur Circle, Sompur Gate, Kada Agrahara Anekal, Bangalore, Bengaluru, Karnataka 562125",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000077,
        "name": "Ambiance Design Studio",
        "role": "merchant",
        "meta": {
            "email_id": "manasa@ambiancedesignstudio.in",
            "contact_no": "948 284 0704 (Manasa Bharadwaj)",
            "locality": "Raja Rajeshwarinagar",
            "address": "117, 'SHANDILYA', Ground Floor, 1st Cross, 4th Stage, Raja Rajeshwarinagar",
            "website_url": "http://ambiancedesignstudio.in/",
            "facebook_url": "https://www.facebook.com/Ambiancedesignstudio"
        }
    },
    {
        "userid": 33000078,
        "name": "NewARCH Design",
        "role": "merchant",
        "meta": {
            "email_id": "meghna@newarchdesign.com , info@newarchdesign.com",
            "contact_no": "‭70192 75035‬",
            "locality": "Mahadevpura",
            "address": "5th Cross Road, Behind Udupi Park Hotel, Garudachar Palya, Mahadevapura",
            "website_url": "https://newarchdesign.com/home/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000079,
        "name": "CJ Sheth",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "080953 53299 (Jinesh Sheth)",
            "locality": "Chickpet",
            "address": "4, Kilari Rd, Anchepet, Chickpet, Bengaluru, Karnataka 560053",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000080,
        "name": "Furdo",
        "role": "merchant",
        "meta": {
            "email_id": "hello@furdo.com",
            "contact_no": "080-46809127",
            "locality": "Koramangala",
            "address": "#2, Indus Vogue Apartments, 1st main, Koramangala 6th Block",
            "website_url": "https://www.furdo.com/",
            "facebook_url": "https://www.facebook.com/furdoDOTcom"
        }
    },
    {
        "userid": 33000081,
        "name": "Antarangni",
        "role": "merchant",
        "meta": {
            "email_id": "antarangni@gmail.com",
            "contact_no": "9741887766 , 9902887766 (Manish)",
            "locality": "Sarjapur Road",
            "address": "#23, Axis Bank Building, Next to Fisherman's Wharf, Off Harlur Road, Off Sarjapur Rd, Bengaluru, Karnataka 560102",
            "website_url": "http://www.antarangniinterior.com/",
            "facebook_url": "https://www.facebook.com/antarangniinterior/"
        }
    },
    {
        "userid": 33000082,
        "name": "Varshan Studios",
        "role": "merchant",
        "meta": {
            "email_id": "info@varshanstudios.com",
            "contact_no": "70222 99120, 90362 78451 (Senthil Kumar)",
            "locality": "Jogupalya",
            "address": "No 56, Saibaba Temple Road, Cambridge Layout",
            "website_url": "http://varshanstudios.com",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000083,
        "name": "Kuvio Studio",
        "role": "merchant",
        "meta": {
            "email_id": "richa@kuviostudio.com",
            "contact_no": 7760299007,
            "locality": "Koramangala",
            "address": "2nd Floor, 4A, 8th Main Road, Koramangala 4 Block, Koramangala",
            "website_url": "http://www.kuviostudio.com/",
            "facebook_url": "https://www.facebook.com/KuvioStudio"
        }
    },
    {
        "userid": 33000084,
        "name": "Praxis",
        "role": "merchant",
        "meta": {
            "email_id": "admin@cmsmpraxis.in",
            "contact_no": 8095353299,
            "locality": "Ejipura",
            "address": "Architecture + Interiors, #18, Srinivagilu Main Road, Ejipura",
            "website_url": "http://www.praxis-india.com",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000085,
        "name": "Noah Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "sandesh@noahinteriors.com",
            "contact_no": 7676670670,
            "locality": "Ulsoor",
            "address": "12, 1st Floor Narayan Reddy colony, Annaswamy Mudaliar Road, Ulsoor Lake, Ulsoor",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/noahinteriors/"
        }
    },
    {
        "userid": 33000086,
        "name": "Spacewood",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9900602355 (Fiona),099455 56533",
            "locality": "Banaswadi",
            "address": "#443, V.P Arcade, 1st Block, 5th A Cross, 9th B Main Road, HRBR Layout,Kalyan Nagar, Banasawadi, Bengaluru, Karnataka 560043",
            "website_url": "https://spacewood.in/",
            "facebook_url": "https://business.facebook.com/SpacewoodIndia"
        }
    },
    {
        "userid": 33000087,
        "name": "Ados Home Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "Contact@ados.in",
            "contact_no": "7349308401 (Sneha), 9964994166 (Mr. Srinivas)",
            "locality": "Whitefield",
            "address": "#09, 2nd Floor, Park Squire Mall, ITPL, Whitefield",
            "website_url": "http://ados.in/",
            "facebook_url": "https://www.facebook.com/ados.in/"
        }
    },
    {
        "userid": 33000088,
        "name": "Savio & Rupa",
        "role": "merchant",
        "meta": {
            "email_id": "savioandrupa@gmail.com, suppliers@savioandrupa.com",
            "contact_no": "080-25530777, 080-25525777, 080-25505777",
            "locality": "Koramangala",
            "address": "91, 2nd floor, 1st C Main Road, Jakkasandra extension, Koramangala",
            "website_url": "http://www.savioandrupa.com/",
            "facebook_url": "https://www.facebook.com/savioandrupa/"
        }
    },
    {
        "userid": 33000089,
        "name": "Inner Space Design",
        "role": "merchant",
        "meta": {
            "email_id": "info@innerspacedesign.co.in",
            "contact_no": "9980833308 (Yogesh), +919663373306",
            "locality": "Kannamangala",
            "address": "25, Sobha Adamus, Off Whitefield Hoskote Road, Kannamangala, Bengaluru, Karnataka 560067",
            "website_url": "http://www.innerspacedesign.co.in/",
            "facebook_url": "https://www.facebook.com/innerspaceindia"
        }
    },
    {
        "userid": 33000090,
        "name": "D24 interiors",
        "role": "merchant",
        "meta": {
            "email_id": "d24 interiors@gmail.com",
            "contact_no": "09980611011 (jeevan)",
            "locality": "KR Puram",
            "address": "#456, Beerappa Layout KS Halli, Virgo Nagar Post, Medahalli , KR Puram",
            "website_url": "https://d24interiors.wixsite.com/d24interiors",
            "facebook_url": "https://www.facebook.com/D24INTERIORS/"
        }
    },
    {
        "userid": 33000091,
        "name": "Kriya Living",
        "role": "merchant",
        "meta": {
            "email_id": "hello@kriyadesign.in",
            "contact_no": "9886079777- Richard , 080-41741715",
            "locality": "Nandidurga Road, Jayamahal",
            "address": "11/4, Jayamahal Main Rd, Munimarappa Garden, Jayamahal Extension, Jayamahal, Bengaluru, Karnataka 560046",
            "website_url": "http://www.kriyadesign.in/",
            "facebook_url": "https://www.facebook.com/KriyaDesignStudio/"
        }
    },
    {
        "userid": 33000092,
        "name": "Mehai Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "mehai.interior@gmail.com",
            "contact_no": "9945073952 (Hari Singh)",
            "locality": "Whitefield",
            "address": "Seegehalli Gate, Kadugudi",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/mehaidesigns/"
        }
    },
    {
        "userid": 33000093,
        "name": "Artis Interiorz",
        "role": "merchant",
        "meta": {
            "email_id": "artisinteriorz@gmail.com, seema@artisinteriorz.com",
            "contact_no": "9986661736, 9611567002",
            "locality": "Whitefield",
            "address": "Unit No : 11 & 12,\nFirst Floor, Arya Hub\nHope Farm Junction\nWhitefield",
            "website_url": "http://www.artisinteriorz.com",
            "facebook_url": "https://www.facebook.com/artisinteriorzpvtltd/"
        }
    },
    {
        "userid": 33000094,
        "name": "Ganesh Interior Designs",
        "role": "merchant",
        "meta": {
            "email_id": "ganeshidecor@yahoo.in",
            "contact_no": 9845756562,
            "locality": "J P Nagar",
            "address": "Kothnur Main Rd, Nrupathunga Nagar, Kothnoor Dinne, 8th Phase, JP Nagar",
            "website_url": "https://ganeshid.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000095,
        "name": "Decor Dreams",
        "role": "merchant",
        "meta": {
            "email_id": "atul@decordreams.in, contact@decordreams.in, hr@decordreams.in, sales@decordreams.in",
            "contact_no": "9972647272 (Atul Jain - owner), 8880 084 084 (general)",
            "locality": "JP Nagar",
            "address": "#473, Swathi Towers, 2nd floor, RBI Layout, Kothnur Main Road, JP Nagar 7th Phase, Bengaluru, Karnataka 560078",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000096,
        "name": "Dream Touch Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "dreamtouch.blore@gmail.com, info@dreamtouchinteriors.com",
            "contact_no": "9739254789, 9900930707 (Vidula Niranjan)",
            "locality": "Hulimavu",
            "address": "SLN Regalia, Krishna Kuteer Resturant Circle, Avani Sringeri Nagar, Hulimavu, Bengaluru, Karnataka 560076",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000097,
        "name": "Scale Inch",
        "role": "merchant",
        "meta": {
            "email_id": "info@scaleinch.com, sales@scaleinch.com",
            "contact_no": "076767 60027",
            "locality": "Indiranagar",
            "address": "# 622, 2nd Floor, 12th A Main Rd, HAL 2nd Stage, Indiranagar, Bengaluru, Karnataka 560008",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000098,
        "name": "Excalibur Furnishes",
        "role": "merchant",
        "meta": {
            "email_id": "Mr. Adithya",
            "contact_no": "092067 78899, 9900098881",
            "locality": "JP Nagar",
            "address": "#51&52, 4th floor, S V PLAZA, KSRTC Layout, 2nd Phase, J. P. Nagar, Bengaluru, Karnataka 560078",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000099,
        "name": "R K Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "rk_interiors@yahoo.com , prashanth@rkinteriors.org , rajesh@rkinteriors.org",
            "contact_no": "9986002370 - Prashanth Rao. A",
            "locality": "HSR Layout",
            "address": "No. 147, 3rd Floor, HSR Layout, Sector V, Outer Ring Road, Bangalore- 560102",
            "website_url": "http://rkinteriors.org/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000100,
        "name": "Hofeto",
        "role": "merchant",
        "meta": {
            "email_id": "lisa@huzzpa.com, hello@huzzpa.com",
            "contact_no": "9900071655 (Lisa Mohapatra - founder), 080 395 12575",
            "locality": "Koramangala",
            "address": "42, 100 Feet Rd, Koramangala 4th Block, Koramangala, Bengaluru, Karnataka 560034",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000101,
        "name": "KM Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9916448352 (Kailash), 9845572147 (Nawaz Pasha), 089717 57746",
            "locality": "Sarjapur Main Rd, Dommasandra",
            "address": "",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000102,
        "name": "BN Architects",
        "role": "merchant",
        "meta": {
            "email_id": "info@bnarchitects.co.in",
            "contact_no": "080-25217543, 080-25217544",
            "locality": "HAL II Stage",
            "address": "Balan + Nambisan Architects, #226, 1st C Cross, 18th Main, HAL II Stage",
            "website_url": "https://bnarchitects.co.in/",
            "facebook_url": "https://www.facebook.com/BNArchitects/"
        }
    },
    {
        "userid": 33000103,
        "name": "Houz Décor",
        "role": "merchant",
        "meta": {
            "email_id": "info@houzdecor.com",
            "contact_no": 9731797797,
            "locality": "Marathahalli",
            "address": "1284 Old Airport Road, Above Kabab Mirchi, Marthahalli",
            "website_url": "http://www.houzdecor.com/",
            "facebook_url": "https://www.facebook.com/houzdecor/"
        }
    },
    {
        "userid": 33000104,
        "name": "Agarwal Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "manoj@agarwalestates.com",
            "contact_no": "7760001122, 0014049234567,",
            "locality": "Marathahalli",
            "address": "No.9, CRM Soubhagya Annexe, #35/1B, Varthur main road, Near Spice Garden, Marathahalli",
            "website_url": "http://www.agarwalestates.com/",
            "facebook_url": "https://www.facebook.com/AgarwalEstates"
        }
    },
    {
        "userid": 33000105,
        "name": "Louis Wood",
        "role": "merchant",
        "meta": {
            "email_id": "abrar@louiswooddesigncafe.com",
            "contact_no": "7406389000 (Abrar - same person as Lusso Reale)",
            "locality": "MG Road",
            "address": "91, Springboard 45/3, Residency Road, MG road, Shanthakumaran Nagar, Ashok Nagar, Bangalore, Karnataka - 560025.",
            "website_url": "http://www.louiswooddesigncafe.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000106,
        "name": "Ace Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "info@aceinteriors.in",
            "contact_no": "9620216546, 9632119007 (Gurvinder)",
            "locality": "HSR Layout",
            "address": "149/ A, 5th main, 6th sector, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000107,
        "name": "Cubedecors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": 9663224224,
            "locality": "Marathahalli",
            "address": "Ground Floor, SR Arcade, 6th Cross, Tulasi Theatre Road, Marathahalli,, Marathahalli Village, Marathahalli, Bengaluru, Karnataka 560037",
            "website_url": "https://www.cubedecors.com/",
            "facebook_url": "https://www.facebook.com/cubedecors.in/"
        }
    },
    {
        "userid": 33000108,
        "name": "Space Trend",
        "role": "merchant",
        "meta": {
            "email_id": "info@spacetrend.in",
            "contact_no": "9845493553 ( Sandeep)",
            "locality": "Harlur",
            "address": "VNB Interiors Ecommerce LLP, 12/56, Shubh Enclave, 1st Avenue, 9th Main, Harlur Road",
            "website_url": "http://spacetrend.in/",
            "facebook_url": "https://www.facebook.com/SpaceTrend/"
        }
    },
    {
        "userid": 33000109,
        "name": "Ambadas",
        "role": "merchant",
        "meta": {
            "email_id": "info@ambadas.in",
            "contact_no": "9448396320 (Geetha Lakshmi)",
            "locality": "Koramangala / Marathahalli",
            "address": "No. 648, 6th Block, 17th Main, 100 Feet Road, Near Sony World, 6th Block",
            "website_url": "http://ambadas.in/",
            "facebook_url": "https://www.facebook.com/ambadas.in/"
        }
    },
    {
        "userid": 33000110,
        "name": "GJ Studio",
        "role": "merchant",
        "meta": {
            "email_id": "geeta@geetajohn.com",
            "contact_no": "98450 11595",
            "locality": "Hennur",
            "address": "D 703, Plama Heights, Hennur Main Road",
            "website_url": "https://www.gjstudio.org/",
            "facebook_url": "https://www.facebook.com/GeetaJohnStudio"
        }
    },
    {
        "userid": 33000111,
        "name": "Huespace Designs",
        "role": "merchant",
        "meta": {
            "email_id": "mail@huespace.in",
            "contact_no": "99803 77471, 080-41571455, 080-42012721",
            "locality": "Dommasandra",
            "address": "341/285/1, First Floor, Varthur Main Road, Dommasandra P.O",
            "website_url": "http://www.huespace.in/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000112,
        "name": "Renterio",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "092424 24441",
            "locality": "",
            "address": "Badrappa, SR Layout, Main Road",
            "website_url": "https://www.renterio.in/",
            "facebook_url": "https://www.facebook.com/Renterio/"
        }
    },
    {
        "userid": 33000113,
        "name": "Cee Bee Design Studio",
        "role": "merchant",
        "meta": {
            "email_id": "chitralekha@ceebeedesignstudio.com",
            "contact_no": "7477722403 , 8240717853 ,9830550570",
            "locality": "HSR Layout",
            "address": "C-06, Startup Huts, #108, 2nd & 3rd Floor, 27 Main, Sector 2, HSR Layout",
            "website_url": "https://www.ceebeedesignstudio.com/",
            "facebook_url": "https://www.facebook.com/ceebeedesignstudio/"
        }
    },
    {
        "userid": 33000114,
        "name": "Pascal",
        "role": "merchant",
        "meta": {
            "email_id": "pascalvisuals@gmail.com",
            "contact_no": "9886081157(Pradyoth sharma)",
            "locality": "",
            "address": "",
            "website_url": "http://pascalvisuals.com/",
            "facebook_url": "https://www.facebook.com/pascalstudios/"
        }
    },
    {
        "userid": 33000115,
        "name": "Atom Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "contact@atominteriors.com, dujohn@atominteriors.com",
            "contact_no": "9481494815,",
            "locality": "Mathikere",
            "address": "41,SLV Chambers, 2nd Floor, Opp Corporation Bank, New BEL Rd, AG’s Layout, Mathikere",
            "website_url": "https://www.atominteriors.com/",
            "facebook_url": "https://www.facebook.com/atomhomeinteriors/"
        }
    },
    {
        "userid": 33000116,
        "name": "Tusker SS 304 Kitchen Modules",
        "role": "merchant",
        "meta": {
            "email_id": "info@tuskerindia.in",
            "contact_no": "080-2671 4007",
            "locality": "Banashankari",
            "address": "Prasara Innotech Private Limited, #3, Ground Floor, 4th Main, 24th Cross, Karesandra, Banashankari 2nd Stage",
            "website_url": "http://www.tuskerindia.in/",
            "facebook_url": "https://www.facebook.com/tuskerss304kitchenmodules/"
        }
    },
    {
        "userid": 33000117,
        "name": "Beth Living",
        "role": "merchant",
        "meta": {
            "email_id": "sales@bethliving.com, dlr@bethliving.com, roshankitchens@gmail.com",
            "contact_no": "9845922823 (Esmail)",
            "locality": "JP Nagar",
            "address": "173, 9th Block, Further Extension, Anjanapura BDA Layout, J P Nagaraj, 9 th Phase",
            "website_url": "http://bethliving.com",
            "facebook_url": "https://www.facebook.com/mybethliving/"
        }
    },
    {
        "userid": 33000118,
        "name": "HN Neo Design & Build",
        "role": "merchant",
        "meta": {
            "email_id": "hnassociatesblr@gmail.com",
            "contact_no": "9845378458(Harinathan), 9742595002",
            "locality": "Kalyannagar",
            "address": "No 206, 1st FLoor, 3rd Block, HRBR Layout, 5th A Cross, Kalyannagar Post",
            "website_url": "http://www.hnndb.com/",
            "facebook_url": "https://www.facebook.com/HN-Neo-Design-Build-Pvt-Ltd-416123672053062/"
        }
    },
    {
        "userid": 33000119,
        "name": "Vijayalakshmi Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9916196247(Raju)",
            "locality": "",
            "address": "",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000120,
        "name": "Decfort",
        "role": "merchant",
        "meta": {
            "email_id": "hashim@decfort.com",
            "contact_no": "7795026654(Hashim)",
            "locality": "Indiranagar",
            "address": "#F1,2nd Floor,Premier Court, Chinmaya Mission Hospital Rd, Indiranagar, Bengaluru, Karnataka 560038",
            "website_url": "www.decfort.com",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000121,
        "name": "ONYX Interio",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "‭081970 93356‬",
            "locality": "Ramamurthy nagar",
            "address": "195, Main Rd, Hoysala Nagar, Ramamurthy Nagar, Bengaluru, Karnataka 560016",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000122,
        "name": "Woodville",
        "role": "merchant",
        "meta": {
            "email_id": "blr@woodvilleindia.com",
            "contact_no": "080-25631269, 080-25631270",
            "locality": "Koramangala",
            "address": "#6, 1st Floor, 4th Block, 80 Feet Road, Koramangala",
            "website_url": "https://www.woodvilleindia.com/",
            "facebook_url": "https://www.facebook.com/woodville/"
        }
    },
    {
        "userid": 33000123,
        "name": "Kayak Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "kayakkitchens@gmail.com",
            "contact_no": "9880491595 (Santosh Kumar),099865 37811",
            "locality": "Jayanagar",
            "address": "938, 28th Main Rd, Corporation Colony, Jayanagara 9th Block, Jayanagar, Bengaluru, Karnataka 560069",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000124,
        "name": "Best Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "mail.best@ymail.com",
            "contact_no": "9986997250 (Syed Javed)",
            "locality": "",
            "address": "",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000125,
        "name": "Futura Home Interior",
        "role": "merchant",
        "meta": {
            "email_id": "futurahomeinterior@gmail.com, btsubramani@rediffmail.com, subramanibt@rediffmail.com , futurabts@gmail.com",
            "contact_no": "9663835003, 9066078434, 9448078434",
            "locality": "Lakkasandra",
            "address": "Futura Home Interior, 2/2, Ground Floor, PID No. 62-10-2, 1st Main Road, Lakkasandra",
            "website_url": "http://www.futurainterior.com/",
            "facebook_url": "https://www.facebook.com/Futurahomeinteriors/?fref=ts"
        }
    },
    {
        "userid": 33000126,
        "name": "MySquareFeet",
        "role": "merchant",
        "meta": {
            "email_id": "info@mysquarefeet.space",
            "contact_no": "+91 96115 04567",
            "locality": "Malleshwaram",
            "address": "#285, First Floor, Between 17th & 18th Cross, Malleshwaram",
            "website_url": "http://www.mysquarefeet.space/",
            "facebook_url": "https://www.facebook.com/Mysquarefeet.space/"
        }
    },
    {
        "userid": 33000127,
        "name": "FSD (Form Space and Design)",
        "role": "merchant",
        "meta": {
            "email_id": "vishal@formspacedesign.com, career@formspacedesign.com",
            "contact_no": "Vishal -9741101414,080 25533555",
            "locality": "Koramangala",
            "address": "A block, A Block, Prestige Atlanta, 1, 80 Feet Rd, Koramangala, Bengaluru, Karnataka 560034",
            "website_url": "http://www.formspacedesign.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000128,
        "name": "Dlife Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "abhijith@dlifeinteriors.com, sales@dlifeinteriors.com",
            "contact_no": "8606970154 (Abhijith)",
            "locality": "HSR Layout",
            "address": "760, 19th Main Rd, Garden Layout, Sector 2, HSR Layout",
            "website_url": "https://www.dlifeinteriors.com/",
            "facebook_url": "https://www.facebook.com/dlifehome/"
        }
    },
    {
        "userid": 33000129,
        "name": "Aristo",
        "role": "merchant",
        "meta": {
            "email_id": "sales@aristo-india.com",
            "contact_no": "8880 160160, 099000 25227",
            "locality": "Koramangala",
            "address": "100 Feet Rd, KHB Block Koramangala, Madiwala, 1st Stage, Koramangala, Bengaluru, Karnataka 560095",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000130,
        "name": "Kitchen Zone",
        "role": "merchant",
        "meta": {
            "email_id": "kitchenzone07@gmail.com",
            "contact_no": "099002 70235 (Ravindra)",
            "locality": "JP Nagar",
            "address": "#13, 26/1B, Jaraganahalli, Kanakapura Main Road, J. P. Nagar Post",
            "website_url": "https://kitchenzones.in/",
            "facebook_url": "https://www.facebook.com/Kitchen-zone-kz-bangalore-967346559996362/"
        }
    },
    {
        "userid": 33000131,
        "name": "Geometrixs Architects & Engineers",
        "role": "merchant",
        "meta": {
            "email_id": "info@geometrixs.com",
            "contact_no": "080-2663 8195",
            "locality": "Jayanagar",
            "address": "A/81, 31st Cross, Jayanagar 7th Block",
            "website_url": "http://geometrixs.com/",
            "facebook_url": "https://www.facebook.com/GeometrixsArchitectsEngineers/?fref=ts"
        }
    },
    {
        "userid": 33000132,
        "name": "Voglia Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "sony@voglia.com",
            "contact_no": "9108005205",
            "locality": "Kammanahalli",
            "address": "No.1/1, 1st Floor, Vasavi Arcade, Kammanahalli Main Road",
            "website_url": "http://www.voglia.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000133,
        "name": "Wurzel Home",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "9353003060",
            "locality": "HSR",
            "address": "No. 17, 1st Floor, 9th Main, 17th Cross Road, VII Sector, HSR Layout, Bengaluru, Karnataka 560102",
            "website_url": "https://www.wurzelhome.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000134,
        "name": "Aegis Design Studio",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "7353777757 (Satya Kiran), 8310468137 , 991055002(Sachin)",
            "locality": "JP Nagar",
            "address": "12, 24th Main, 14th Cross Rd, 1st Phase, J. P. Nagar, Bengaluru, Karnataka 560078",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000135,
        "name": "Sree Sai Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "",
            "contact_no": "Amarnath - 80951 77826",
            "locality": "Begurr",
            "address": "AECS B Block, Wellington Paradise, Begur, Bengaluru, Karnataka 560068",
            "website_url": "https://www.sreesaiinteriors.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000136,
        "name": "A N Dezignz",
        "role": "merchant",
        "meta": {
            "email_id": "andezignz@gmail.com",
            "contact_no": "Indrajeet- 88611 33002",
            "locality": "Bannerghatta",
            "address": "1st Floor, Shree Durga Complex, Bannerghatta Main Rd, Central Excise Layout, Phase 2, Tejaswini Nagar, Bengaluru, Karnataka 560076",
            "website_url": "https://www.andezignz.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000137,
        "name": "Homes Under Budget",
        "role": "merchant",
        "meta": {
            "email_id": "ranjithpraj@gmail.com",
            "contact_no": "Ranjith P Raj - 9036531393",
            "locality": "Ramamurthy Nagar",
            "address": "#11, First Floor Near Ramamurthy Nagar Under Pass, B Channasandra Rd, Bangalore",
            "website_url": "https://homesunderbudget.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000138,
        "name": "PropEden",
        "role": "merchant",
        "meta": {
            "email_id": "tarun@propeden.com",
            "contact_no": "SIRIKI TARUN BABU - 9177922211",
            "locality": "THUBRAHALLI",
            "address": "5/3 3TH FLOOR, THUBRAHALLI, ABOVE BIRIYANI ZONE, BANGALORE 560066",
            "website_url": "https://www.propeden.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000139,
        "name": "Buildflic",
        "role": "merchant",
        "meta": {
            "email_id": "saad@buildflic.com",
            "contact_no": "9177754542",
            "locality": "Hyderabad",
            "address": "S G Apartment, opp to mahindra spare parts new hafezpet, Hyderabad",
            "website_url": "https://www.buildflic.com/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000140,
        "name": "Ghar Ek Sapna",
        "role": "merchant",
        "meta": {
            "email_id": "anurag.kesarwani1@gmail.com",
            "contact_no": "9666445582",
            "locality": "Hyderabad",
            "address": "7-1/6, Nallagandla, Huda Residential layout, Tellapur road, Hyderabad 500019",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000141,
        "name": "Living Interior",
        "role": "merchant",
        "meta": {
            "email_id": "info.livinginterior@gmail.com",
            "contact_no": "9711541786",
            "locality": "Greater Noida",
            "address": "N2/05 Eros Sampoornam Greater Noida West Sector 2 Uttar Pradesh 201301",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000142,
        "name": "Elavin Kitchen & Home Interior",
        "role": "merchant",
        "meta": {
            "email_id": "elavinkitchen@gmail.com",
            "contact_no": "9049682624",
            "locality": "Pune",
            "address": "Pune, Maharashtra",
            "website_url": "https://elavin-kitchen-kitchen-furniture-store.business.site/",
            "facebook_url": "https://www.facebook.com/elavinkitchen/"
        }
    },
    {
        "userid": 33000143,
        "name": "Prointero Interior",
        "role": "merchant",
        "meta": {
            "email_id": "prointeropune@gmail.com",
            "contact_no": "8010066341",
            "locality": "Tingre Nagar, Pune",
            "address": "102, Rajkamal, Lane no. 10A, Tingre Nagar, Pune, Maharashtra - 411032",
            "website_url": "https://www.prointero.com/",
            "facebook_url": "https://www.facebook.com/prointero/"
        }
    },
    {
        "userid": 33000144,
        "name": "Mayu Interiors",
        "role": "merchant",
        "meta": {
            "email_id": "mayuinteriors@gmail.com",
            "contact_no": "9032430905",
            "locality": "Amberpet, Hyderabad",
            "address": "H.no: 2-3-60/71, Purnodaya Colony, Amberpet, Hyderabad - 500013",
            "website_url": "http://www.mayuinteriors.com/",
            "facebook_url": "https://www.facebook.com/mayuinteriors/"
        }
    },
    {
        "userid": 33000145,
        "name": "Revanth Infratech",
        "role": "merchant",
        "meta": {
            "email_id": "chalamalarevanth@gmail.com",
            "contact_no": "8801111814",
            "locality": "BK Guda Road, Hyderabad",
            "address": " 8A, 302 Abhilah Towers, BK Guda Road, Hyderabad",
            "website_url": "https://www.rdesign.in/",
            "facebook_url": "https://www.facebook.com/revanthdesign/"
        }
    },
    {
        "userid": 33000146,
        "name": "KiksAR Technologies",
        "role": "merchant",
        "meta": {
            "email_id": "kiran@kiksarvr.com",
            "contact_no": "9986287899",
            "locality": "Whitefield, Bangalore",
            "address": "Gayatri Tech Park,183 KIADB EPIP Zone, Whitefield, Bangalore",
            "website_url": "http://kiksarvr.com/",
            "facebook_url": "https://www.linkedin.com/company/kiksar/"
        }
    },
    {
        "userid": 33000147,
        "name": "Vansh Decor",
        "role": "merchant",
        "meta": {
            "email_id": "vanshdecor@yahoo.com",
            "contact_no": "9620302323",
            "locality": "Kalyan Nagar Post, Bangalore",
            "address": "No. 24, 1st Floor, Nanjundeshwara Complex, Hennur Main Rd, Kalyan Nagar Post, Bangalore - 560043",
            "website_url": "",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000148,
        "name": "Bhatiya Interior Expert",
        "role": "merchant",
        "meta": {
            "email_id": "bhatiyainteriorexpert@gmail.com",
            "contact_no": "9967430773",
            "locality": "Moti Nagar, Delhi",
            "address": "C/7, Sudarshan Park, Moti Nagar, Delhi",
            "website_url": "https://www.bhatiyainteriorexpert.com/",
            "facebook_url": "https://www.facebook.com/bhatiyainterior"
        }
    },
    {
        "userid": 33000149,
        "name": "LUXDECO",
        "role": "merchant",
        "meta": {
            "email_id": "contactdkumar@gmail.com",
            "contact_no": "8605332168",
            "locality": "Ghorpadi, Pune",
            "address": "Ground Floor, Insignia, Shop no. 2 & 3, Bund Garden Road, Opp. Wadia College, Ghorpadi, Pune (Maharashtra) - 411001",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/lux.deco.5"
        }
    },
    {
        "userid": 33000150,
        "name": "Design Definer",
        "role": "merchant",
        "meta": {
            "email_id": "saif.sayyed2010@gmail.com",
            "contact_no": "9561857367",
            "locality": "Pune",
            "address": "507, Nana Peth, SH Kadri Tower, Pune (Maharashtra)",
            "website_url": "",
            "facebook_url": "https://www.facebook.com/designdefiner/"
        }
    },
    {
        "userid": 33000151,
        "name": "Dimensions Unlimited",
        "role": "merchant",
        "meta": {
            "email_id": "ashfaque99@gmail.com",
            "contact_no": "9821390308",
            "locality": "Malad (W), Mumbai",
            "address": "501, Sonata A Wing, Jankalyan Nagar, Off Malad Marve Road, Malad West, Mumbai (Maharashtra)",
            "website_url": "https://ashfaq_abdulgani.houzz.in/",
            "facebook_url": ""
        }
    },
    {
        "userid": 33000152,
        "name": "Izan",
        "role": "merchant",
        "meta": {
            "email_id": "ishita@izangroup.com",
            "contact_no": "9773299030",
            "locality": "JB Nagar, Andheri (W), Mumbai",
            "address": "JB Nagar, Andheri (W), Mumbai (Maharashtra)",
            "website_url": "http://izangroup.com/",
            "facebook_url": "https://www.instagram.com/izan_architecture/"
        }
    },
    {
        "userid": 33000153,
        "name": "Dharma Interior",
        "role": "merchant",
        "meta": {
            "email_id": "dharmainteriormumbai@gmail.com",
            "contact_no": "8693033301",
            "locality": "Kandivali (W), Mumbai",
            "address": "Shop no. 20, Balaji Arcade Ground Floor, SV Road, Kandivali (W), Mumbai - 400101, Maharashtra",
            "website_url": "http://www.dharmainterior.com/",
            "facebook_url": "https://www.facebook.com/dharmainterior/"
        }
    }
]

module.exports = formattedMerchantList;