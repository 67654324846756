import React, { Component } from 'react';
import { FormComponent, FormItem } from '../../common/Forms';
import { Icon, Divider, Input, DatePicker, message as Message, Row, Button, Select, Col, Modal, Tooltip } from 'antd';
import WhatsAppIcon from '../../../images/whatsapp-logo.png';
import MailIcon from '../../../images/mail-icon.png';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Actions from '../../../redux/actions/rootActions';
import API from '../../../api';
import LoanStorage from '../../../utils/loanStorage';
import DummyFixture from '../../../utils/dummy';
import MerchantUtils from '../../../utils/merchant';
import MasterData from '../../../data';
import FormElements from './formElements';
import moment from 'moment';

const uuidv4 = require('uuid/v4');
class LoanInformationForm extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.actions = bindActionCreators(Actions, dispatch);
        this.state = {
            showKnowMorePopup: false,
            showFormSubmitPopup: false,
            disableSubmit: false,
            isFormSubmitted: false
        };
    }

    componentDidMount() {
        const loanGuid = this.getGUIDParam();
        const storedLoanData = this.loadLoanData(loanGuid);
        if (loanGuid && !storedLoanData) {
            this.props.history.replace('/loan');
        } else {
            const serializedLoanData = this.serializeStoredLoanData(storedLoanData);
            this.props.form.setFieldsValue(serializedLoanData.borrower);
            this.setState({
                isFormSubmitted: !!serializedLoanData.guid,
                showFormSubmitPopup: !!serializedLoanData.guid
            });
        }
    }

    loadLoanData(guid) {
        let loanData = guid ? LoanStorage.getByGUID(guid) : LoanStorage.getDraftLoan();
        return loanData || null;
    }

    serializeStoredLoanData(loanData = {}) {
        const serializedLoanData = {
            borrower: {},
            merchant: {},
            ...loanData
        }
        if (serializedLoanData.borrower && serializedLoanData.borrower.dob) {
            serializedLoanData.borrower.dob = moment(serializedLoanData.borrower.dob);
        }
        return serializedLoanData;
    }

    getGUIDParam() {
        const { guid } = this.props.match.params;
        return guid;
    }

    fillDummyData() {
        let fieldKeys = Object.keys(DummyFixture.loan);
        let data = {};
        fieldKeys.forEach((key) => {
            data[key] = this.props.form.getFieldValue(key) || DummyFixture.loan[key];
        })
        this.props.form.setFieldsValue({
            ...data
        })
    }

    async createLoan(data) {
        try {
            return await API.createNewLoan(data);
        } catch (err) {
            window.Rollbar.error("Unable to create loan", err);
        }
    }

    createFormSubmissionData(values) {
        let merchantData = MerchantUtils.getMerchantDataFromUrl();
        let borrowerData = {
            ...values
        };
        let loanData = {
            borrower: borrowerData,
            guid: uuidv4(),
            merchant: merchantData
        }
        return this.sanitizeFormData(loanData);

    }

    sanitizeFormData(loanData) {
        let sanitizedLoanData = {
            ...loanData
        }
        sanitizedLoanData.borrower.dob = sanitizedLoanData.borrower.dob.format('YYYY-MM-DD');
        return sanitizedLoanData;
    }

    submitLoanToExternal(loanData) {
        window.Rollbar && window.Rollbar.log("New Loan created: Browser Direct", { loan: loanData });
    }

    async submitLoanToServer(loanData) {
        try {
            await API.createNewLoan(loanData);
        } catch (err) {
            window.Rollbar.error("Unable to create loan");
        }
    }

    submitLoanLocally(loanData) {
        LoanStorage.addNew({
            ...loanData,
            isSubmited: true
        });
    }

    submitLoanData(data) {
        this.submitLoanLocally(data);
        this.submitLoanToExternal(data);
        this.submitLoanToServer(data);
    }

    showSuccessPopup(loanData) {
        window.history.pushState(null, null, `/loan/${loanData.guid}`);
        setTimeout(async () => {
            Message.destroy();
            this.setState({
                showFormSubmitPopup: true
            });
        }, 3000);
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.setState({
            disableSubmit: true
        });
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                let loanData = this.createFormSubmissionData(values);
                Message.loading('Submitting Loan, please wait...', 0);
                this.submitLoanData(loanData);
                this.showSuccessPopup(loanData);
            } else {
                this.setState({
                    disableSubmit: false
                });
            }
        });
    }

    getCityStateFromPincode(pincode, pinCodeMaster) {
        let pincodeIndex = pinCodeMaster.pinCode.indexOf(parseInt(pincode));
        if (!pincodeIndex || pincodeIndex < 0) {
            return '';
        }
        let city = pinCodeMaster.city[pincodeIndex];
        let state = pinCodeMaster.state[pincodeIndex];
        return `${city}, ${state}`;
    }

    showModal = (modal) => {
        this.setState({
            [modal]: true,
        });
    };

    handleStateChange(type) {
        let resetField = type === 'company' ? 'companyCity' : 'residenceCity';
        this.props.form.resetFields([resetField]);
    }

    handleOk = (e, popup) => {
        this.setState({
            [popup]: false,
        });
    };

    handleCancel = (e, popup) => {
        this.setState({
            [popup]: false,
        });
    };

    handleTenureChange(val) {
        let loanAmount = this.props.form.getFieldValue('loanRequired');
        this.setTentativeEMIAmount(this.parseToNum(loanAmount), this.parseToNum(val));
    }

    parseToNum(str) {
        return parseInt(str);
    }

    getLocalizedNumber(num) {
        return num.toLocaleString('en-IN');
    }

    getTentativeEMIAmount(loanAmount, prefTenure) {
        if (loanAmount && prefTenure) {
            const tenureCalculationData = MasterData.emiCalculation[`${prefTenure}`];
            const emiAmountRangeStart = tenureCalculationData.interestAmount[0];
            const emiAmountRangeEnd = tenureCalculationData.interestAmount[tenureCalculationData.interestAmount.length - 1];
            const emiRangeStartForLoanAmount = Math.round((loanAmount / 100000) * emiAmountRangeStart);
            const emiRangeEndForLoanAmount = Math.round((loanAmount / 100000) * emiAmountRangeEnd);
            return `${this.getLocalizedNumber(emiRangeEndForLoanAmount)} - ${this.getLocalizedNumber(emiRangeStartForLoanAmount)}`;
        }
        return '';
    }

    setFieldValue(key, value) {
        this.props.form.setFieldsValue({
            [key]: value
        })
    }

    getFieldValue(key) {
        return this.props.form.getFieldValue(key);
    }

    handleFieldChange(val, key) {
        console.log("Event", val, key);
        if (['companyPinCode', 'residencePinCode'].includes(key)) {
            let cityStateValue = this.getCityStateFromPincode(val, MasterData.pinCodeMaster);
            let updateKey = (key === 'companyPinCode') ? 'companyCityState' : 'residenceCityState';
            this.setFieldValue(updateKey, cityStateValue);
        }
        if (['loanRequired', 'emiTenure'].includes(key)) {
            let loanAmount = this.getFieldValue('loanRequired');
            let prefTenure = (key === "emiTenure") ? val : this.getFieldValue('emiTenure');
            let tentativeEMIAmount = this.getTentativeEMIAmount(loanAmount, prefTenure);
            this.setFieldValue('tentativeEmiCalculation', tentativeEMIAmount);
        }
    }

    renderSubmitButton() {
        let isDisabled = this.isFormDisabled();
        return (
            <FormItem>
                <Button type="primary" htmlType="submit" disabled={isDisabled}>
                    Submit<Icon type="cloud-upload" />
                </Button>
            </FormItem>
        );
    }

    renderKnowMoreModal() {
        return (
            <Modal
                title="Boro EMI FAQs"
                visible={this.state.showKnowMorePopup}
                onOk={(e) => this.handleOk(e, 'showKnowMorePopup')}
                onCancel={(e) => this.handleCancel(e, 'showKnowMorePopup')}
                footer={null}
                width="80%"
                style={{
                    top: '2'
                }}
            >
                <iframe className='know-more-modal' title="Know More" src="https://vaibhav698131.typeform.com/to/CU2aGE" frameBorder="0" width="100%"></iframe>
            </Modal>
        )
    }

    renderFormSubmitModal() {
        return (
            <Modal
                title="Thank you for choosing Boro!"
                visible={this.state.showFormSubmitPopup}
                onOk={(e) => this.handleOk(e, 'showFormSubmitPopup')}
                onCancel={(e) => this.handleCancel(e, 'showFormSubmitPopup')}
                style={{
                    top: '2'
                }}
                centered={true}
                footer={[
                    <Button style={{marginRight: '4px'}} key="whatsapp" type="link" onClick={this.handleCancel} href="https://wa.me/919019053015" target="_blank">
                        <img src={WhatsAppIcon} alt="WhatsApp" width='24px'/>
                        <span style={{paddingLeft: '3px'}}> WhatsApp </span>
                    </Button>,
                    <Button key="email" type="link" onClick={this.handleOk} href="mailto:emi@boroin.com" target="_blank">
                        <img src={MailIcon} alt="Mail" width='20px'/>
                        <span style={{paddingLeft: '3px'}}> Email </span>
                    </Button>,
                  ]}
            >
                <div>
                    Hey, you're almost there! To allow us to get you the best terms possible, please WhatsApp (+91-9019053015) or Email (emi@boroin.com) us soft-copies of your:
                    <br />
                    <ul>
                        <li>Bank Statement (last 6 months) </li>
                        <li>Salary Slips (last 3 months)</li>
                        <li>PAN Card </li>
                        <li> Address Proof (permanent & current). </li>
                    </ul>
                </div>
            </Modal>
        )
    }

    renderDivider() {
        return (
            <Divider />
        );
    }

    renderFormHeader() {
        return (
            <div style={{ marginBottom: "22px", borderBottom: "1px solid black" }}>
                <span style={{ fontWeight: "bold", fontSize: "24px" }}> Check EMI Eligibility </span>
                <Tooltip title="Have a question?">
                    <span onClick={(e) => this.showModal('showKnowMorePopup')}>
                        <Icon
                            style={{ fontSize: '22px', marginLeft: '12px', cursor: 'pointer' }}
                            type="question-circle"
                        />
                    </span>
                </Tooltip>
            </div>
        );
    }

    renderSectionHeader(header) {
        return (
            <div style={{ marginBottom: "24px" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px", borderBottom: "1px solid black" }}> {header} </span>
            </div>
        );
    }

    renderSelectOptions(options) {
        let Option = Select.Option;
        return options.map((option) => {
            return (
                <Option key={option.value} value={option.value}>{option.label}</Option>
            );
        })
    }

    isFormDisabled() {
        return !!(this.state.isFormSubmitted || this.state.disableSubmit);
    }

    renderFormInput({ formOptions, elemProps }) {
        let { decoratorKey } = formOptions;
        const disabled = this.isFormDisabled() || !!elemProps.disabled;
        return (
            <Input {...elemProps} disabled={disabled} onBlur={(e) => this.handleFieldChange(e.target.value, decoratorKey)} />
        );
    }

    renderFormDatePicker({ formOptions, elemProps }) {
        let { decoratorKey } = formOptions;
        const dateFormat = 'DD/MM/YYYY';
        const disabled = this.isFormDisabled() || !!elemProps.disabled;;
        return (
            <DatePicker
                disabled={disabled}
                format={dateFormat}
                placeholder='Date of Birth'
                style={{ width: '100%' }}
                onChange={(e) => this.handleFieldChange(e, decoratorKey)}
            />
        );
    }

    renderFormSelect({ formOptions, elemProps, selectOptions }) {
        let { decoratorKey } = formOptions;
        const disabled = this.isFormDisabled() || !!elemProps.disabled;
        return (
            <Select {...elemProps} disabled={disabled} onChange={(e) => this.handleFieldChange(e, decoratorKey)}>
                {this.renderSelectOptions(selectOptions)}
            </Select>
        );
    }

    renderElement(element) {
        let formElement = null;
        let { decoratorKey, decoratorOptions, ...extraFormOptions } = element.formOptions;
        switch (element.formType) {
            case 'input':
                formElement = this.renderFormInput(element);
                decoratorOptions['validateTrigger'] = "onBlur";
                break;
            case 'datepicker':
                formElement = this.renderFormDatePicker(element);
                break;
            case 'select':
                formElement = this.renderFormSelect(element);
                break;
            default:
                return null;
        }
        return (
            <FormItem
                decoratorKey={decoratorKey}
                decoratorOptions={decoratorOptions}
                {...extraFormOptions}
                classNames={`form-item-${decoratorKey}`}
            >
                {formElement}
            </FormItem>
        );
    }

    renderDummyFillButton() {
        if (MerchantUtils.isDummyMerchant()) {
            const disabled = this.isFormDisabled();
            return (
                <FormItem>
                    <Button disabled={disabled} type="primary" htmlType="button" className="boro-dummy-fill-button" size="small" onClick={(e) => this.fillDummyData()}>
                        Dummy Fill<Icon type="right" />
                    </Button>
                </FormItem>
            );
        }
        return null;
    }

    renderAddressDetails() {
        return (
            <React.Fragment>
                {this.renderSectionHeader("Residence details")}
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.residenceType)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.residenceDuration)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.residencePinCode)}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 10 }}
                    >
                        {this.renderElement(FormElements.residenceCityState)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 14 }}
                    >
                        {this.renderElement(FormElements.residenceAddress)}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderCompanyDetails() {
        return (
            <React.Fragment>
                {this.renderSectionHeader("Work details")}
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.typeOfEmployment)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.monthlyIncome)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.nameOfFirm)}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.typeOfCompany)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.officialEmailId)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.officePinCode)}
                    </Col>

                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 10 }}
                    >
                        {this.renderElement(FormElements.officeCityState)}
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 14 }}
                    >
                        {this.renderElement(FormElements.officeAddress)}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                </Row>
            </React.Fragment>
        )
    }

    renderPersonalDetails() {
        return (
            <React.Fragment>
                {this.renderSectionHeader("Basic details")}
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.firstName)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.middleName)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.lastName)}
                    </Col>
                </Row>
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.mobileNo)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.emailId)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.dateOfBirth)}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.gender)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.maritalStatus)}
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.qualification)}
                    </Col>
                </Row>
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.totalWorkExp)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.panCard)}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderTentativeEmiSubtext() {
        const emiTenure = this.getFieldValue('emiTenure');
        const tenureData = MasterData.emiCalculation[`${emiTenure}`];
        if (tenureData && tenureData.interestRate) {
            return (
                <div style={{ fontWeight: 'bold' }}>
                    Interest Rate(p.a.) starting at <span style={{ color: '#237a9b' }}>{tenureData.interestRate}</span> !
                </div>
            );
        }
        return null;
    }

    renderLoanDetails() {
        return (
            <React.Fragment>
                {this.renderSectionHeader('Loan details')}
                <Row type='flex' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.loanAmount)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.emiTenure)}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderElement(FormElements.tentativeEmi)}
                        {this.renderTentativeEmiSubtext()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderFormComponent() {
        return (
            <div style={{
                border: '1px solid #dad2d2',
                padding: '28px 48px',
                borderRadius: '8px',
                marginBottom: '24px',
                backgroundColor: 'white'
            }}>
                <FormComponent form={this.props.form} onSubmit={e => this.onFormSubmit(e)}>
                    {this.renderLoanDetails()}
                    {this.renderDivider()}
                    {this.renderPersonalDetails()}
                    {this.renderDivider()}
                    {this.renderAddressDetails()}
                    {this.renderDivider()}
                    {this.renderCompanyDetails()}
                    {this.renderSubmitButton()}
                    {this.renderDummyFillButton()}

                </FormComponent>
            </div>
        );
    }

    render() {
        return (
            <Row type='flex' justify="center">
                <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={16}
                >
                    {this.renderFormHeader()}
                    {this.renderFormComponent()}
                    {this.renderKnowMoreModal()}
                    {this.renderFormSubmitModal()}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reducerData: state
    }
}

export default connect(
    mapStateToProps
)(LoanInformationForm);