
import amplitude from 'amplitude-js/amplitude';

export const init = function (...initEventData) {
    if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance().init('d905c5ad80f20664adb2cf16d88bfe8a');
        logEvent(...initEventData);
    }
};

export const logEvent = function(...eventData) {
    if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance() && amplitude.getInstance().logEvent && amplitude.getInstance().logEvent(...eventData);
    }
}