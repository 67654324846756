import React, { Component } from 'react';
import { FormComponent, FormItem } from '../../common/Forms';
import { Icon, Input, DatePicker, Radio, AutoComplete, Button, Select, Checkbox, Row, Col } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Actions from '../../../redux/actions/rootActions';
import history from '../../../utils/history';
import DummyFixture from '../../../utils/dummy';
import MerchantUtils from '../../../utils/merchant';

const TYPES_OF_TREATMENT = ["Bariatric", "Bone & Joints (Orthopaedics)", "Cancer (Oncology)", "Cardio Vascular (Angioplasty)", "Cardiology (Heart Valves/ Surgery)", "CRDM (Pace Makers)", "Dental", "Diabetology", "ENT", "Gastrointestinal", "General Surgery", "Hair Transplant", "Hearing Device", "IVF", "Neurology & Neurosurgery", "Obstetrics & Gynaecology", "Ophthalmology (Eye related)", "Pediatrics", "Physiotherapy", "Piles", "Plastic Surgery", "Psychotherapy", "Radiology", "Rheumatology", "Skin related (Dermatology)", "Spine", "Trauma & Critical Care (Accident)", "Urology"]
class ProductInformationForm extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        this.actions = bindActionCreators(Actions, dispatch);
    }

    renderTreatmentTypesDropdown() {

        const dataSource = TYPES_OF_TREATMENT;
        return (
            <FormItem
                decoratorKey="treatmentType"
                decoratorOptions={{
                    initialValue: '',
                    rules: [{ required: true, message: 'Type of treatment is required' }]
                }}
                label="Type of Treatment"
            >

                <AutoComplete
                    style={{ width: '100%' }}
                    dataSource={dataSource}
                    placeholder="Type of Treatment"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 300 }}
                    size="large"
                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                >
                    <Input suffix={<Icon type="search" />} />
                </AutoComplete>
            </FormItem>
        )
    }

    renderSubmitButton() {
        return (
            <FormItem>
                <Button type="primary" htmlType="submit" className="boro-submit-button" size="large">
                    Next<Icon type="right" />
                </Button>
            </FormItem>
        );
    }

    fillDummyData() {
        let fieldKeys = Object.keys(DummyFixture.product);
        let data = {};
        fieldKeys.forEach((key) => {
            data[key] = this.props.form.getFieldValue(key) || DummyFixture.product[key];
        })
        this.props.form.setFieldsValue({
            ...data
        })
    }

    renderDummyFillButton() {
        if (MerchantUtils.isDummyMerchant()) {
            return (
                <FormItem>
                    <Button type="primary" htmlType="button" className="boro-dummy-fill-button" size="small" onClick={(e) => this.fillDummyData()}>
                        Dummy Fill<Icon type="right" />
                    </Button>
                </FormItem>
            );
        }
        return null;
    }

    renderPrefTenure() {
        let Option = Select.Option;
        return (
            <FormItem
                decoratorKey="emiTenure"
                decoratorOptions={{
                    rules: [{ required: true, message: 'EMI Tenure is required' }]
                }}
                label="EMI Tenure (months)"
            >
                <Select style={{ width: 120 }} showSearch allowClear placeholder="EMI Tenure (months)">
                    <Option value="6">6</Option>
                    <Option value="12">12</Option>
                    <Option value="18">18</Option>
                    <Option value="24">24</Option>
                    <Option value="30">30</Option>
                    <Option value="36">36</Option>
                    <Option value="48">48</Option>
                </Select>
            </FormItem>
        )
    }

    renderInputButton(key) {
        let placeholder = '';
        let required = true;
        let requiredMessage = '';
        let type = 'string';
        let addonBefore = null;
        let additionalValidationRules = [];
        switch (key) {
            case 'estimatedCost':
                placeholder = 'Estimated Cost';
                requiredMessage = 'Estimated Cost is required';
                type = 'number';
                addonBefore = '₹';
                break;
            case 'loanRequired':
                placeholder = 'Loan Amount';
                requiredMessage = 'Loan amount is required';
                type = 'number';
                addonBefore = '₹';
                break;
            case 'approxEMI':
                placeholder = 'Approx. EMI';
                requiredMessage = 'EMI is required';
                type = 'number';
                addonBefore = '₹';
                break;
            case 'firstName':
                placeholder = 'First Name';
                requiredMessage = 'First Name is required';
                break;
            case 'lastName':
                placeholder = 'Last Name';
                requiredMessage = 'Last Name is required';
                break;
            case 'middleName':
                placeholder = 'Middle Name';
                required = false;
                break;
            case 'mobileNo':
                placeholder = "Mobile no.";
                requiredMessage = 'Mobile no. is required';
                type = 'number';
                addonBefore = '+91';
                additionalValidationRules = [
                    { validator: (rule, value, callback) => {
                        let mobileRegex = RegExp("^[6-9]\\d{9}$");
                        if (value && !mobileRegex.test(value)) {
                            callback("Please enter valid mobile number!");
                        } else {
                            callback();
                        }
                    }}
                ]
                break;
            default:
                placeholder = ''
        };
        return (
            <FormItem
                decoratorKey={key}
                decoratorOptions={{
                    initialValue: '',
                    validateTrigger: "onBlur",
                    rules: [{ required: required, message: requiredMessage }, ...additionalValidationRules],
                }}
                label={placeholder}
            >
                <Input addonBefore={addonBefore} placeholder={placeholder} type={type} className="boro-form-input" />
            </FormItem>
        );
    }

    renderIsPatientBorrower() {
        return (
            <FormItem
                decoratorKey={'isBorrower'}
                decoratorOptions={{ valuePropName: 'checked', initialValue: true }}
            >
                <Checkbox> <span style={{fontWeight: 'bold'}}>Is patient also borrower </span> </Checkbox>
            </FormItem>
        )
    }

    renderDOB() {
        const dateFormat = 'DD/MM/YYYY';
        return (
            <FormItem
                decoratorKey={'dob'}
                decoratorOptions={{
                    rules: [
                        { required: true, message: 'Date of Birth is required' },
                        { validator: (rule, value, callback) => {
                            if (value && (value.diff(Date.now(), 'days') > -1)) {
                                callback("Date of Birth cannot be in future!");
                            } else {
                                callback();
                            }
                        }}
                    ]
                }}
                label="Date of Birth"
            >
                <DatePicker format={dateFormat} placeholder='Date of Birth' style={{ width: '100%' }} />
            </FormItem>
        );
    }

    renderGender() {
        let RadioGroup = Radio.Group;
        return (
            <FormItem
                decoratorKey={'gender'}
                decoratorOptions={{
                    rules: [{ required: true, message: "Gender is required" }]
                }}
                label="Gender"
            >
                <RadioGroup style={{ width: '100%' }} buttonStyle='solid'>
                    <Radio.Button value={'male'}>Male</Radio.Button>
                    <Radio.Button value={'female'}>Female</Radio.Button>
                </RadioGroup>
            </FormItem>
        );
    }

    onFormSubmit(e) {
        e.preventDefault();
        let currentProductValue = this.props.reducerData.product;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.actions.setValue({
                    product: {
                        ...currentProductValue,
                        ...values
                    }
                });
                history.push('/borrower');
            }
        });
    }

    renderNameSection() {
        return (
            <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('firstName')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('middleName')}
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                >
                    {this.renderInputButton('lastName')}
                </Col>
            </Row>
        )
    }

    renderPersonalDetails() {
        return (
            <React.Fragment>
                {this.renderNameSection()}
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderInputButton('mobileNo')}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderDOB()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                    >
                        {this.renderGender()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderTreatmentSection() {
        return (
            <React.Fragment>
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        {this.renderTreatmentTypesDropdown()}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        {this.renderInputButton('estimatedCost')}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        {this.renderInputButton('loanRequired')}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 6 }}
                    >
                        {this.renderPrefTenure()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderEMISection() {
        return (
            <React.Fragment>
                <Row type='flex' justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    {/* <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                    >
                        {this.renderInputButton('approxEMI')}
                    </Col> */}
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                    >
                        {this.renderPrefTenure()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }


    renderFormComponent() {
        return (
            <div style={{
                border: '1px solid #dad2d2',
                padding: '28px 48px',
                borderRadius: '8px',
            }}>
                <FormComponent form={this.props.form} onSubmit={e => this.onFormSubmit(e)}>
                    {this.renderPersonalDetails()}
                    {this.renderTreatmentSection()}
                    {this.renderIsPatientBorrower()}
                    {this.renderSubmitButton()}
                    {this.renderDummyFillButton()}
                </FormComponent>
            </div>
        );
    }

    renderFormHeader() {
        return (
            <div style={{ marginBottom: "22px", borderBottom: "1px solid black" }}>
                <span style={{ fontWeight: "bold", fontSize: "24px" }}> Patient Details </span>
            </div>
        );
    }

    render() {
        return (
            <Row type='flex' justify="center">
                <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={16}
                >
                    {this.renderFormHeader()}
                    {this.renderFormComponent()}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reducerData: state
    }
}

export default connect(
    mapStateToProps
)(ProductInformationForm);
