import Mid33000014Logo from '../images/merchant-logo/33000014.jpg';
import Mid33000017Logo from '../images/merchant-logo/33000017.png';
import Mid33000018Logo from '../images/merchant-logo/33000018.jpg';
import Mid33000019Logo from '../images/merchant-logo/33000019.png';
import Mid33000020Logo from '../images/merchant-logo/33000020.png';
import Mid33000021Logo from '../images/merchant-logo/33000021.png';
import Mid33000022Logo from '../images/merchant-logo/33000022.png';
import Mid33000023Logo from '../images/merchant-logo/33000023.png';
import Mid33000024Logo from '../images/merchant-logo/33000024.jpg';
import Mid33000027Logo from '../images/merchant-logo/33000027.png';
import Mid33000033Logo from '../images/merchant-logo/33000033.jpg';
import Mid33000034Logo from '../images/merchant-logo/33000034.jpg';
import Mid33000132Logo from '../images/merchant-logo/33000132.png';
import Mid33000029Logo from '../images/merchant-logo/33000029.jpg';
import Mid33000135Logo from '../images/merchant-logo/33000135.jpg';
import Mid33000136Logo from '../images/merchant-logo/33000136.png';
import Mid33000137Logo from '../images/merchant-logo/33000137.png';
import Mid33000138Logo from '../images/merchant-logo/33000138.png';
import Mid33000139Logo from '../images/merchant-logo/33000139.jpg';
import Mid33000140Logo from '../images/merchant-logo/33000140.jpg';
import Mid33000142Logo from '../images/merchant-logo/33000142.png';
import Mid33000143Logo from '../images/merchant-logo/33000143.png';
import Mid33000144Logo from '../images/merchant-logo/33000144.jpg';
import Mid33000145Logo from '../images/merchant-logo/33000145.png';
import Mid33000148Logo from '../images/merchant-logo/33000148.jpg';
import Mid33000149Logo from '../images/merchant-logo/33000149.png';
import Mid33000150Logo from '../images/merchant-logo/33000150.jpg';
import Mid33000152Logo from '../images/merchant-logo/33000152.png';

export const MerchantLogoList = {
    Mid33000014Logo,
    Mid33000017Logo,
    Mid33000018Logo,
    Mid33000019Logo,
    Mid33000020Logo,
    Mid33000021Logo,
    Mid33000022Logo,
    Mid33000023Logo,
    Mid33000024Logo,
    Mid33000027Logo,
    Mid33000033Logo,
    Mid33000034Logo,
    Mid33000132Logo,
    Mid33000029Logo,
    Mid33000135Logo,
    Mid33000136Logo,
    Mid33000137Logo,
    Mid33000138Logo,
    Mid33000139Logo,
    Mid33000140Logo,
    Mid33000142Logo,
    Mid33000143Logo,
    Mid33000144Logo,
    Mid33000145Logo,
    Mid33000148Logo,
    Mid33000149Logo,
    Mid33000150Logo,
    Mid33000152Logo
}