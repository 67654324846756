import React, { Component } from 'react';
import { Form } from 'antd';


class FormComponent extends Component {
    render() {
        const onFormSubmit = this.props.onSubmit || function () { };
        return (
            <FormContext.Provider value={this.props.form}>
                <Form onSubmit={onFormSubmit}>
                    {this.props.children}
                </Form>
            </FormContext.Provider>
        );
    }
}

export const FormContext = React.createContext({});

export default FormComponent;
